<template>
  <div class="payment-common">
    <div v-if="!fromOrder">
      <b-row v-if="outGoingAmount">
        <b-col md="6">
          <b class="textTitlePayment text-warning"> Hoàn tiền: </b>
        </b-col>
        <b-col md="6">
          <p class="text-right textFinal textTitlePayment text-warning">
            {{ convertPrice(outGoingAmount) }}
          </p>
        </b-col>
      </b-row>
      <b-row v-if="remainAmount > 0 && !excludes.fields.includes('excessCash')">
        <b-col md="6">
          <b
            class="textTitlePayment"
            :class="{
              textFinal: titleFinal === 'Còn thiếu',
              textExcessCash: titleFinal === 'Tiền thừa',
            }"
          >
            {{ titleFinal }}:
          </b>
        </b-col>
        <b-col md="6">
          <p class="text-right textFinal textTitlePayment">
            {{ convertPrice(remainAmount) }}
          </p>
        </b-col>
      </b-row>
    </div>
    <b-form-group :disabled="disabled" v-if="activePayment[PAYMENT_TYPE.CASH]">
      <PaymentMultipleItems
        :label="fromOrder ? 'đặt cọc' : 'tiền mặt'"
        :paymentType="PAYMENT_TYPE.CASH"
        :items="cash"
        :disabled="disabled"
        :options="filteredCashAccOptions"
        :validate="validateCash"
        :max="0"
        @select="onSelectedMul($event, PAYMENT_TYPE.CASH)"
        @change="onInputChange($event, PAYMENT_TYPE.CASH)"
      >
        <template #bind-money="{ index, disabled }">
          <b-button
            :disabled="disabled"
            style="width: 40px"
            variant="secondary"
            size="sm"
            @click="bindingIncomingMoney(index, PAYMENT_TYPE.CASH)"
          >
            <i class="fas fa-arrow-right"></i>
          </b-button>
        </template>
      </PaymentMultipleItems>
    </b-form-group>
    <b-form-group
      :disabled="disabled"
      v-if="activePayment[PAYMENT_TYPE.TRANSFER]"
    >
      <PaymentMultipleItems
        label="chuyển khoản"
        :paymentType="PAYMENT_TYPE.TRANSFER"
        :items="transfer"
        :disabled="disabled"
        :options="filteredTransferAccOptions"
        :validate="validateTransfer"
        @change="onInputChange($event, PAYMENT_TYPE.TRANSFER)"
        @select="onSelectedMul($event, PAYMENT_TYPE.TRANSFER)"
      >
        <template #bind-money="{ index, disabled }">
          <b-button
            :disabled="disabled"
            style="width: 40px"
            variant="secondary"
            size="sm"
            @click="bindingIncomingMoney(index, PAYMENT_TYPE.TRANSFER)"
          >
            <i class="fas fa-arrow-right"></i>
          </b-button>
        </template>
        <template #other="{ item, check, disabled }">
          <b-form-group v-if="check(item)" :disabled="disabled" class="mb-2">
            <b-form-input
              class="input-style"
              size="sm"
              autocomplete="off"
              v-model="item.paymentCode"
              placeholder="Mã tham chiếu"
            ></b-form-input>
          </b-form-group>
        </template>
        <template #qrCode="{ item }">
          <div class="payment-via-qr-code">
            <div class="qr-code-checkbox">
              <b-form-checkbox
                :id="String(item.id)"
                v-if="item.paymentAmount > 0 && isAllowQRCode"
                v-model="item.viaQRCode"
              >
                Dùng QR code
              </b-form-checkbox>

              <span class="account-bank-no text-info font-weight-bold">{{
                item.identifyCode ? item.identifyCode : item.accountBankNo
              }}</span>
            </div>
            <b-button
              v-if="item.viaQRCode && item.paymentAmount > 0"
              size="sm"
              class="generate-qr-button"
              @click="onClickGenerateQR(item)"
              >Tạo mã thanh toán</b-button
            >
          </div>
        </template>
      </PaymentMultipleItems>
    </b-form-group>
    <b-form-group
      :disabled="disabled"
      v-if="activePayment[PAYMENT_TYPE.CREDIT]"
    >
      <PaymentMultipleItems
        label="quẹt thẻ"
        :paymentType="PAYMENT_TYPE.CREDIT"
        :items="credits"
        :disabled="disabled"
        :options="filteredCreditAccOptions"
        :validate="validateCredit"
        @select="onSelectedMul($event, PAYMENT_TYPE.CREDIT)"
        @change="onInputChange($event, PAYMENT_TYPE.CREDIT)"
      >
        <template #bind-money="{ index, disabled }">
          <b-button
            :disabled="disabled"
            style="width: 40px"
            variant="secondary"
            size="sm"
            @click="bindingIncomingMoney(index, PAYMENT_TYPE.CREDIT)"
          >
            <i class="fas fa-arrow-right"></i>
          </b-button>
        </template>
        <template #other="{ item, check, disabled }">
          <b-form-group v-if="check(item)" :disabled="disabled" class="mb-2">
            <b-form-input
              class="input-style"
              size="sm"
              v-model="item.creditCardNo"
              placeholder="Nhập 4 số cuối mã thẻ"
            ></b-form-input>
          </b-form-group>
          <b-form-group v-if="check(item)" :disabled="true" class="mb-2">
            <b-form-input
              class="input-style"
              size="sm"
              v-model="item.paymentCode"
              placeholder="Mã giao dịch"
            ></b-form-input>
          </b-form-group>

          <b-form-group v-if="check(item)" :disabled="disabled">
            <label>Hình thức trả phí:</label>
            <b-form-select
              class="select-style"
              v-model="item.payCreditFeeType"
              :options="listPayCreditFeeType"
              size="sm"
              value-field="id"
              text-field="name"
              @change="onCreditFeePayTypeChange"
            >
              <template v-slot:first>
                <b-form-select-option :value="null"
                  >Chọn hình thức trả phí quẹt thẻ</b-form-select-option
                >
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group v-if="check(item)" :disabled="disabled">
            <b-form-input
              class="input-style text-right"
              size="sm"
              v-model="item.creditCardFee"
              placeholder="Phí quẹt thẻ"
              v-mask="mask"
            ></b-form-input>
          </b-form-group>
          <b-form-group v-if="check(item)" :disabled="disabled">
            <label>Tài khoản thu phí quẹt thẻ:</label>
            <Autosuggest
              placeholder="tài khoản chuyển khoản"
              :model="item.creditFeeAccountantName"
              :suggestions="filteredCreditFeeAccOptions"
              @change="onInputChange($event, PAYMENT_TYPE.CREDIT_FEE)"
              @select="onSelectedMul($event, PAYMENT_TYPE.CREDIT_FEE)"
              :state="validateCreditFee"
            />
          </b-form-group>
        </template>
      </PaymentMultipleItems>
    </b-form-group>
    <b-form-group
      :disabled="disabled"
      v-if="activePayment[PAYMENT_TYPE.INSTALLMENT]"
    >
      <PaymentMultipleItems
        label="trả góp"
        :paymentType="PAYMENT_TYPE.INSTALLMENT"
        :items="installments"
        :disabled="disabled"
        :options="filteredOptionsInstallment"
        :validate="validateInstallment"
        @select="onSelectedMul($event, PAYMENT_TYPE.INSTALLMENT)"
        @change="onInputChange($event, PAYMENT_TYPE.INSTALLMENT)"
      >
        <template #label-right="{ index }">
          <div v-if="index === 0">
            <div class="d-flex">
              <small
                v-if="isInterestZero"
                class="text-primary mt-1 mr-1"
                style="margin-top: 0.5px"
                >Trả góp 0%</small
              >
              <small
                v-if="isInterestShinhan"
                class="text-success mt-1 mr-1"
                style="margin-top: 0.5px"
                >Shinhan</small
              >
              <b-dropdown
                class="dropdown-custom"
                size="sm"
                offset="25"
                right
                style="margin-top: 2px"
                no-caret
              >
                <template #button-content>
                  <span>
                    <i
                      class="fa fa-cog ml-1"
                      aria-hidden="true"
                      :class="isInterestZero ? 'text-primary' : ''"
                      style="font-size: 1.1rem"
                    ></i>
                  </span>
                </template>
                <b-dropdown-group header="Trả góp 0%">
                  <b-dropdown-item @click="onChangeInterest">
                    <b-form-checkbox
                      switch
                      v-model="isInterestZero"
                      class="mb-0"
                      >Kích hoạt
                    </b-form-checkbox>
                  </b-dropdown-item>
                  <b-dropdown-item-button @click="onShowInterestProgram">
                    <i
                      class="fa fa-tasks ml-1 mr-2 text-primary"
                      aria-hidden="true"
                    ></i>
                    Chương trình
                  </b-dropdown-item-button>
                </b-dropdown-group>
                <b-dropdown-divider></b-dropdown-divider>

                <b-dropdown-group
                  header="Duyệt hồ sơ Shinhan Online"
                  v-if="fromOrder"
                >
                  <b-dropdown-item @click="onChangeShinhan">
                    <b-form-checkbox
                      switch
                      v-model="isInterestShinhan"
                      class="mb-0"
                      >Kích hoạt
                    </b-form-checkbox>
                  </b-dropdown-item>
                </b-dropdown-group>
              </b-dropdown>
            </div>
          </div>
        </template>
        <template #bind-money="{ index, disabled }">
          <b-button
            :disabled="disabled"
            style="width: 40px"
            variant="secondary"
            size="sm"
            @click="bindingIncomingMoney(index, PAYMENT_TYPE.INSTALLMENT)"
          >
            <i class="fas fa-arrow-right"></i>
          </b-button>
        </template>
        <template #other="{ item, index, check, disabled }">
          <b-form-group v-if="check(item)" :disabled="disabled">
            <b-form-input
              class="input-style"
              size="sm"
              v-model="item.paymentCode"
              placeholder="Mã hợp đồng"
            ></b-form-input>
          </b-form-group>
          <b-form-group v-if="check(item)" :disabled="disabled">
            <b-form-input
              class="input-style"
              size="sm"
              v-model="item.customerIndentifyNo"
              placeholder="Số CMND"
            ></b-form-input>
          </b-form-group>
          <div v-if="showInstallmentKredivoOptions(item)">
            <b-button
              v-if="!item.pendingTransactionId"
              size="sm"
              class="generate-qr-button"
              @click="onClickInstallmentKredivo(item)"
              >Gửi mã thanh toán KREDIVO</b-button
            >
            <b-button
              v-if="item.pendingTransactionId"
              size="sm"
              class="generate-qr-button"
              @click="onClickCheckStatusKredivo(item)"
              >Kiểm tra trạng thái giao dịch Kredivo</b-button
            >
            <b-button
              v-if="
                item.pendingTransactionId &&
                  item.pendingTransactionStatus ===
                    PAYMENT_TRANSACTION_STATUS.INITIAL
              "
              size="sm"
              class="generate-qr-button bg-danger text-white"
              @click="onClickCancelTransactionKredivo(item)"
              >Huỷ giao dịch</b-button
            >
          </div>
          <div v-if="isInterestZero && index === 0">
            <PaymentInstallmentInterest
              :paymentType="PAYMENT_TYPE.INSTALLMENT"
              :isInterestZero="isInterestZero"
              :storeId="billData.storeId"
            />
            <PaymentChildItem
              :item="item"
              :interestZeroLoan="interestZeroLoan"
              :disabled="disabled"
              :filteredCashAccOptions="filteredCashAccOptions"
              :filteredTransferAccOptions="filteredTransferAccOptions"
              :filteredCreditAccOptions="filteredCreditAccOptions"
              :filteredOptionsInstallment="filteredOptionsInstallment"
              :cashAccOptions="cashAccOptions"
              :transferOptions="transferOptions"
            />
          </div>
          <div v-show="action === 'update' && isInterestShinhan && index === 0">
            <PaymentInstallmentShinhan
              :paymentType="PAYMENT_TYPE.INSTALLMENT"
              :isInterestShinhan="isInterestShinhan"
              v-on:show-loan-data="showLoanData"
            />
          </div>
        </template>
        <template #qrCode="{ item }">
          <div
            class="payment-via-qr-code"
            v-show="item.paymentRefCode === 'MOMO ONLINE'"
          >
            <div class="qr-code-checkbox">
              <b-form-checkbox
                :id="String(item.id)"
                v-if="item.paymentAmount > 0 && isAllowQRCode"
                v-model="item.viaQRCode"
              >
                Dùng QR code
              </b-form-checkbox>

              <span class="account-bank-no text-info font-weight-bold">{{
                item.identifyCode ? item.identifyCode : item.accountBankNo
              }}</span>
            </div>
            <b-button
              v-if="item.viaQRCode && item.paymentAmount > 0"
              size="sm"
              class="generate-qr-button"
              @click="onClickGenerateQR(item)"
              >Tạo mã thanh toán</b-button
            >
          </div>
        </template>
      </PaymentMultipleItems>
    </b-form-group>
    <b-form-group
      :disabled="disabled"
      v-if="activePayment[PAYMENT_TYPE.REFUND]"
    >
      <PaymentMultipleItems
        label="chi hoàn tiền"
        :paymentType="PAYMENT_TYPE.REFUND"
        :items="refunds"
        :disabled="disabled"
        :options="filteredOptionsRefunds"
        :validate="validateRefund"
        @select="onSelectedMul($event, PAYMENT_TYPE.REFUND)"
        @change="onInputChange($event, PAYMENT_TYPE.REFUND)"
      >
        <template #bind-money="{ index, disabled }">
          <b-button
            :disabled="disabled"
            style="width: 40px"
            variant="secondary"
            size="sm"
            @click="bindingOutgoingMoney(index, PAYMENT_TYPE.REFUND)"
          >
            <i class="fas fa-hand-holding-usd"></i>
          </b-button>
        </template>
        <template #other="{ item, index, check, disabled }">
          <b-form-group v-if="check(item)" :disabled="disabled">
            <b-form-select
              :options="[
                {
                  id: null,
                  name: 'Chọn hình thức thanh toán',
                },
                {
                  id: 'TM',
                  name: 'Tiền mặt',
                },
                {
                  id: 'CK',
                  name: 'Chuyển khoản',
                },
              ]"
              v-model="item.paymentOptionType"
              @change="
                onChangeCheckBox({
                  index,
                  value: item.paymentOptionType,
                  paymentType: PAYMENT_TYPE.REFUND,
                })
              "
              size="sm"
              value-field="id"
              text-field="name"
              class="select-style"
            ></b-form-select>
            <b-button
              variant="secondary"
              size="sm"
              block
              class="font-weight-bolder mb-2 mt-2"
              @click="showApprovalModal(item)"
            >
              <i class="fa fa-plus-circle"></i>
              Tạo duyệt chi</b-button
            >
          </b-form-group>
        </template>
      </PaymentMultipleItems>
    </b-form-group>
    <b-button
      variant="secondary"
      size="sm"
      class="font-weight-bolder mb-2"
      block
      @click="popupModalPayment"
    >
      <i class="fa fa-plus-circle"></i>
      Thêm thanh toán</b-button
    >
    <b-row v-if="outGoingAmount">
      <b-col md="6">
        <b class="textTitlePayment text-warning"> Hoàn tiền: </b>
      </b-col>
      <b-col md="6">
        <p class="text-right textFinal textTitlePayment text-warning">
          {{ convertPrice(outGoingAmount) }}
        </p>
      </b-col>
    </b-row>
    <b-row v-if="remainAmount > 0 && !excludes.fields.includes('excessCash')">
      <b-col md="6">
        <b
          class="textTitlePayment"
          :class="{
            textFinal: titleFinal === 'Còn thiếu',
            textExcessCash: titleFinal === 'Tiền thừa',
          }"
        >
          {{ titleFinal }}:
        </b>
      </b-col>
      <b-col md="6">
        <p class="text-right textFinal">
          {{ convertPrice(remainAmount) }}
        </p>
      </b-col>
    </b-row>
    <template v-if="fromOrder">
      <b-row v-if="subtractPointAmount">
        <b-col md="6">
          <b class="text-warning"> Tiêu điểm: </b>
        </b-col>
        <b-col md="6">
          <p class="text-right textFinal">
            {{ convertPrice(subtractPointAmount) }}
          </p>
        </b-col>
      </b-row>
      <div v-if="isInterestZero">
        <b-row>
          <b-col md="6">
            <b> Tiền trả trước: </b>
          </b-col>
          <b-col md="6">
            <p class="text-right textFinal">
              {{ convertPrice(interestZeroPrePaid) }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b> Phí chuyển đổi: </b>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b> Khoản vay: </b>
          </b-col>
          <b-col md="6">
            <p class="text-right textFinal">
              {{ convertPrice(interestZeroLoan) }}
            </p>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col md="6">
          <b class="text-success"> Tổng cộng: </b>
        </b-col>
        <b-col md="6">
          <p class="text-right textFinal">
            {{ convertPrice(finalAmount) }}
          </p>
        </b-col>
      </b-row>
    </template>
    <div v-if="onLoading.shinhan">
      <LoanData
        ref="loan-data-modal"
        :loanApplicationId="getInstallmentShinhan.loanApplicationId"
        :totalAmount="finalAmount"
        :mode="loanDataMode"
        :loanApplicationData="loanApplicationData"
        v-on:onCreateSuccess="onCreateLoanSuccess"
      />
    </div>
    <div v-if="onLoading.interestZero">
      <PaymentInstallmentModal ref="modal-installment-common" />
    </div>
    <div>
      <ApprovalModal ref="popup-approval" />
      <PaymentAdditionModal :excludes="excludes.paymentMethods" />
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import {
  convertPrice,
  currencyMask,
  removeAccents,
  unMaskPrice,
  cloneDeep,
  makeToastFaile,
  makeToastSuccess,
  isFeatureActive,
} from '@/utils/common';
import {
  PAYMENT_TYPE,
  APPROVAL_TYPE_ENUM,
  PAYMENT_TRANSACTION_STATUS,
  INSTALLMENT_CODE,
} from '@/utils/enum';
import { mapGetters } from 'vuex';
import { TIME_TRIGGER, LOAN_STATUS, SHINHAN_ID } from '@/utils/constants';
import {
  SET_INTEREST,
  SET_INSTALLMENT_SHINHAN,
} from '@/core/services/store/modules/payment/payment-installment.module';
import LoanData from '../order/LoanData.vue';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import PaymentMultipleItems from '@/view/components/payments/PaymentMultipleItems.vue';
import PaymentAdditionModal from '@/view/components/payments/PaymentAdditionModal.vue';
import PaymentInstallmentInterest from '@/view/components/payments/PaymentInstallmentInterest.vue';
import PaymentInstallmentShinhan from '../payments/PaymentInstallmentShinhan.vue';
import PaymentInstallmentModal from '@/view/components/payments/PaymentInstallmentModal.vue';
import PaymentChildItem from '@/view/components/payments/PaymentChildItem.vue';
import { commonMixin, swalMixin } from '@/view/mixins/';
import { EventBus } from '@/core/services/event-bus';
import ApprovalModal from '@/view/components/approval/ApprovalModal.vue';

const payCreditFeeType = {
  CASH: 1,
  TRANSFER: 2,
  CREDIT: 3,
};

export default {
  mixins: [swalMixin, commonMixin],
  props: {
    finalAmount: {
      type: Number,
      default: 0,
    },
    billData: {
      type: Object,
    },
    onSubmiting: {
      type: Boolean,
    },
    fromOrder: {
      type: Boolean,
    },
    action: {
      type: String,
    },
    subtractPointAmount: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
    },
    isAllowQRCode: {
      type: Boolean,
    },
    excludes: {
      type: Object,
      default: () => ({
        fields: [],
        paymentMethods: [],
      }),
    },
  },
  data() {
    return {
      isUsingQRCode: false,
      isEnableInstallmentKredivo: false,
      PAYMENT_TYPE,
      PAYMENT_TRANSACTION_STATUS,
      mask: currencyMask,
      filteredCashAccOptions: [],
      cashAccOptions: [
        {
          data: [],
        },
      ],
      filteredTransferAccOptions: [],
      transferOptions: [
        {
          data: [],
        },
      ],
      filteredCreditAccOptions: [],
      creditOptions: [
        {
          data: [],
        },
      ],
      listPayCreditFeeType: [
        {
          id: payCreditFeeType.CASH,
          name: 'Tiền mặt',
        },
        {
          id: payCreditFeeType.TRANSFER,
          name: 'Chuyển khoản',
        },
        {
          id: payCreditFeeType.CREDIT,
          name: 'Quẹt thẻ',
        },
      ],
      filteredCreditFeeAccOptions: [],
      creditFeeAccOptions: [
        {
          data: [],
        },
      ],
      showInsProgram: false,
      selectedInstallmentProgram: null,
      filteredOptionsInstallmentProgram: [],
      optionsMonthNo: [],
      installmentAccountantName: '',
      filteredOptionsInstallment: [],
      optionsInstallment: [
        {
          data: [],
        },
      ],
      creditFeeRate: 0,
      paymentInfo: {
        //tiền mặt
        cashAmount: 0,
        cashAccountantName: '',
        cashAccountCode: '',
        cashAccountId: null,
        //chuyển khoản
        transferAmount: 0,
        transferAccountantName: '',
        transferAccountCode: '',
        transferAccountantId: null,
        transferReferenceCode: '',
        //quẹt thẻ
        creditAmount: 0,
        creditAccountantName: '',
        creditAccountCode: '',
        creditAccountantId: null,
        creditCardNo: '',
        creditCardCode: '',
        creditCode: '',
        //phí quẹt thẻ
        creditCardFee: 0,
        creditFeeAccountantName: '',
        creditFeeAcountCode: '',
        creditFeeAccountantId: null,
        payCreditFeeType: 1,
        //trả góp
        installedMoneyAmount: 0,
        installmentCode: '',
        installmentAccountName: '',
        installMoneyAccountId: null,
        customerIndentifyNo: '',
        installmentProgramMonthNo: 0,
        installmentProgramPrepayPercent: 0,
        installmentProgramType: 1,
        //shinhan bank
        loanApplicationId: null,
        installApplicationStatus: null,
        //refund
        refundAmount: 0,
        refundOptionId: null,
        refundAccountId: null,
        refundAccountCode: null,
      },
      LOAN_STATUS: LOAN_STATUS,
      loanDataMode: '',
      loanApplicationData: {
        prepaidAmount: 0,
        loanAmount: 0,
        phone: '',
        email: '',
        fullName: '',
      },
      cash: [],
      transfer: [],
      credits: [],
      installments: [],
      refunds: [],
      speratePice: 0,
      filteredOptionsRefunds: [],
      isInterestZero: false,
      isInterestShinhan: false,
      onLoading: {
        interestZero: false,
        shinhan: false,
      },
      activePayment: {
        [PAYMENT_TYPE.CASH]: true,
        [PAYMENT_TYPE.TRANSFER]: true,
        [PAYMENT_TYPE.CREDIT]: true,
        [PAYMENT_TYPE.INSTALLMENT]: true,
        [PAYMENT_TYPE.CREDIT_FEE]: true,
        [PAYMENT_TYPE.REFUND]: true,
      },
    };
  },
  components: {
    LoanData,
    Autosuggest,
    PaymentMultipleItems,
    ApprovalModal,
    PaymentAdditionModal,
    PaymentInstallmentInterest,
    PaymentInstallmentModal,
    PaymentInstallmentShinhan,
    PaymentChildItem,
  },
  async created() {
    this.handleExclude();
    this.getDefaultValuePayment();
    if (this.fromOrder) {
      this.convertPaymentFromOrder();
    } else {
      this.convertPaymentFromBill();
    }
    this.isInterestZero = this.getInstallmentInterestZero;
    if (this.isInterestZero) this.onLoading.interestZero = true;
    this.isInterestShinhan = this.getInstallmentInterestShinhan;
    if (this.isInterestShinhan) this.onLoading.shinhan = true;
    this.isEnableInstallmentKredivo = await isFeatureActive(
      'bill-installment-kredivo',
    );
  },
  mounted() {
    this.getCreditFee();
    this.fetchInstallments();
    this.fetchAccountant();
    this.fetchCreditAcc();
  },
  watch: {
    'billData.storeId': {
      handler: async function(newValue) {
        // if (this.fromOrder && this.action === 'update') {
        //   if (!newValue) {
        //     return;
        //   }
        //   const cashId = this.paymentInfo.cashAccountId;
        //   const transferId = this.paymentInfo.transferAccountId;

        //   if (newValue !== oldValue && (cashId || transferId)) {
        //     const text = 'Chỉ chuyển cửa hàng giữ lại thanh toán';
        //     const alert = await this.showWarningAlert(text);
        //     if (alert.isConfirmed) {
        //       return;
        //     }
        //   }
        // }
        this.paymentInfo.cashAccountantName = '';
        this.paymentInfo.cashAccountCode = '';
        this.paymentInfo.cashAccountId = null;
        this.filteredCashAccOptions = [];
        if (newValue) {
          this.fetchCashAcc();
          this.fetchAccountant();
        }
      },
    },
    paymentInfo: {
      handler: function(newValue) {
        this.handleEmitPayment(newValue);
      },
      deep: true,
    },
    installments: {
      handler: function() {
        this.handleEmitPaymentMul(this.installments, PAYMENT_TYPE.INSTALLMENT);
      },
      deep: true,
    },
    credits: {
      handler: function() {
        this.handleEmitPaymentMul(this.credits, PAYMENT_TYPE.CREDIT);
      },
      deep: true,
    },
    transfer: {
      handler: function() {
        this.handleEmitPaymentMul(this.transfer, PAYMENT_TYPE.TRANSFER);
      },
      deep: true,
    },
    cash: {
      handler: function() {
        this.handleEmitPaymentMul(this.cash, PAYMENT_TYPE.CASH);
      },
      deep: true,
    },
    refunds: {
      handler: function() {
        this.handleEmitPaymentMul(this.refunds, PAYMENT_TYPE.REFUND);
      },
      deep: true,
    },
    isInterestShinhan: {
      handler: function(changeValue) {
        if (changeValue) {
          const shinhanInstallment = this.filteredOptionsInstallment.find(
            (installment) => installment.id === String(SHINHAN_ID),
          );
          if (shinhanInstallment) {
            this.installments[0].paymentName = shinhanInstallment.name;
            this.installments[0].paymentRefCode = shinhanInstallment.code;
            this.installments[0].paymentRefId = shinhanInstallment.id;
          }
        } else {
          this.installments[0].paymentName = null;
          this.installments[0].paymentRefCode = null;
          this.installments[0].paymentRefId = null;
        }
      },
    },
    interestZeroLoan: {
      handler: function(newValue) {
        if (!this.isInterestZero || !this.interestZeroPrePaid) {
          return;
        }
        this.installments[0].paymentAmount = newValue;
      },
    },
  },
  computed: {
    ...mapGetters([
      'getInstallmentInterestZero',
      'getInstallmentProgram',
      'getInstallmentInterestShinhan',
      'getInstallmentShinhan',
      'getProducts',
    ]),
    isUpdateBill() {
      return this.$route.name === 'update-bill';
    },
    interestZeroExchange() {
      const [firstInstallment] = this.installments;
      if (firstInstallment) {
        return firstInstallment.child
          ? firstInstallment.child.paymentAmount
          : 0;
      }
      return 0;
    },
    interestZeroLoan() {
      return this.finalAmount - this.interestZeroPrePaid;
    },
    interestZeroPrePaid() {
      return (
        (this.finalAmount *
          this.getInstallmentProgram.installmentProgramPrepayPercent || 0) / 100
      );
    },
    titleFinal() {
      const sumIn = this.sumIncomingAmount();
      let amount = this.finalAmount - sumIn;
      if (amount < 0) {
        const sumOut = this.sumOutgoingAmount();
        amount += sumOut;
      }
      return amount > 0 ? 'Còn thiếu' : 'Tiền thừa';
    },
    outGoingAmount() {
      const sumOut = this.sumOutgoingAmount();
      return sumOut;
    },
    remainAmount() {
      const sumIn = this.sumIncomingAmount();
      const totalAmount = this.finalAmount || 0;
      let remainAmount = totalAmount - sumIn;
      if (remainAmount < 0) {
        const sumOut = this.sumOutgoingAmount();
        remainAmount += sumOut;
      }

      this.$emit('remain-amount', remainAmount);

      return Math.abs(remainAmount);
    },
    validateCash() {
      if (
        unMaskPrice(this.paymentInfo.cashAmount) > 0 &&
        !this.paymentInfo.cashAccountId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
    validateTransfer() {
      if (
        unMaskPrice(this.paymentInfo.transferAmount) > 0 &&
        !this.paymentInfo.transferAccountantId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
    validateCredit() {
      if (
        unMaskPrice(this.paymentInfo.creditAmount) > 0 &&
        !this.paymentInfo.creditAccountantId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
    validateCreditFee() {
      if (
        unMaskPrice(this.paymentInfo.creditCardFee || 0) > 0 &&
        !this.paymentInfo.creditFeeAccountantId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
    validateInstallment() {
      if (
        unMaskPrice(this.paymentInfo.installedMoneyAmount) > 0 &&
        !this.paymentInfo.installMoneyAccountId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
    validateRefund() {
      if (
        unMaskPrice(this.paymentInfo.refundAmount) > 0 &&
        !this.paymentInfo.refundAccountId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    handleExclude() {
      if (!this.excludes.paymentMethods.length) {
        return;
      }
      for (let i = 0; i < this.excludes.paymentMethods.length; i++) {
        const payment = this.excludes.paymentMethods[i];
        if (this.activePayment[payment]) {
          this.activePayment[payment] = false;
        }
      }
    },
    onClickGenerateQR(item) {
      this.$emit('click-generate-qrcode', item);
    },
    onClickInstallmentKredivo(item) {
      this.$emit('click-checkout-kredivo', item);
    },
    onClickCheckStatusKredivo(item) {
      this.$emit('click-check-status-kredivo', item);
    },
    onClickCancelTransactionKredivo(item) {
      this.$emit('click-cancel-transaction-kredivo', item);
    },
    convertPrice,
    convertPaymentFromBill() {
      const billPayment = this.billData.paymentInfo;
      this.paymentInfo = cloneDeep(billPayment);
      if (this.billData.installments.length) {
        this.installments = cloneDeep(this.billData.installments);
      }
      if (this.billData.credits.length) {
        this.credits = cloneDeep(this.billData.credits);
      }
      if (this.billData.transfer.length) {
        this.transfer = cloneDeep(this.billData.transfer);
      }
      if (this.billData.cash.length) {
        this.cash = cloneDeep(this.billData.cash);
      }
      if (this.billData.refunds.length) {
        this.refunds = cloneDeep(this.billData.refunds);
      }
      this.fetchCashAcc();
    },
    convertPaymentFromOrder() {
      if (this.action === 'update') {
        const filterPayment = (items, type, defaultValues) => {
          const itemsFiltered = items.filter(
            (item) => item.paymentType === type,
          );
          return itemsFiltered.length > 0 ? itemsFiltered : defaultValues;
        };
        const payments = this.billData.payments || [];

        this.installments = filterPayment(
          payments,
          PAYMENT_TYPE.INSTALLMENT,
          this.installments,
        );

        this.credits = filterPayment(
          payments,
          PAYMENT_TYPE.CREDIT,
          this.credits,
        );
        this.transfer = filterPayment(
          payments,
          PAYMENT_TYPE.TRANSFER,
          this.transfer,
        );
        this.cash = filterPayment(payments, PAYMENT_TYPE.CASH, this.cash);
        this.refunds = filterPayment(
          payments,
          PAYMENT_TYPE.REFUND,
          this.refunds,
        );
        this.fetchCashAcc();
      }
    },
    isTextNul(text) {
      if (!text || !text.length) {
        return true;
      }
      return false;
    },
    showValid(type) {
      let check = false;
      switch (type) {
        case 'checkInstallMentLoan': {
          check =
            this.checkInstallmentAmount() &&
            this.paymentInfo.installApplicationStatus &&
            this.billData.id;
          break;
        }
        case 'checkInstallMentLoanStatus': {
          check =
            this.checkInstallmentAmount() &&
            this.paymentInfo.installApplicationStatus;
          break;
        }
        default: {
          makeToastFaile('Phương thức kiểm tra không tồn tại');
          break;
        }
      }
      return check;
    },
    handleEmitPayment(value) {
      if (this.fromOrder) {
        const paymentInfo = cloneDeep(value);
        paymentInfo.depositAmount = paymentInfo.cashAmount
          ? unMaskPrice(paymentInfo.cashAmount)
          : 0;

        paymentInfo.transferAmount = paymentInfo.transferAmount
          ? unMaskPrice(paymentInfo.transferAmount)
          : 0;
        paymentInfo.transferAccountId = paymentInfo.transferAccountantId;

        paymentInfo.creditAmount = paymentInfo.creditAmount
          ? unMaskPrice(paymentInfo.creditAmount)
          : 0;
        paymentInfo.creditAccountId = paymentInfo.creditAccountantId;

        paymentInfo.installedMoneyAmount = paymentInfo.installedMoneyAmount
          ? unMaskPrice(paymentInfo.installedMoneyAmount)
          : 0;
        paymentInfo.installMoneyCode = paymentInfo.installmentCode;

        paymentInfo.otherFees = paymentInfo.creditCardFee
          ? unMaskPrice(paymentInfo.creditCardFee)
          : 0;
        paymentInfo.creditFeeAcountId = paymentInfo.creditFeeAccountantId;

        // paymentInfo.creditCardNo = paymentInfo.creditCardNo;

        paymentInfo.creditCode = paymentInfo.creditCardCode;

        this.$emit('receive-payment', paymentInfo);
      } else {
        //handle bill payment
        const paymentInfo = cloneDeep(value);
        this.$emit('receive-payment', paymentInfo);
      }
    },
    handleEmitPaymentMul(payments, type) {
      this.$emit('receive-payment-mul', payments, type);
    },
    sumOutgoingAmount() {
      return [this.refunds].flat().reduce((result, item) => {
        const unMask = unMaskPrice(item.paymentAmount || 0);
        return result + Number(unMask);
      }, 0);
    },
    sumIncomingAmount() {
      return [this.cash, this.transfer, this.credits, this.installments]
        .flat()
        .reduce((result, item) => {
          const unMask = unMaskPrice(item.paymentAmount || 0);
          return result + Number(unMask);
        }, 0);
    },
    sumPaymentAmountByType(index, type) {
      let payments = [];
      switch (type) {
        case PAYMENT_TYPE.CASH: {
          const cash = cloneDeep(this.cash);
          cash.splice(index, 1);
          payments = [cash, this.transfer, this.credits, this.installments];
          break;
        }
        case PAYMENT_TYPE.TRANSFER: {
          const transfer = cloneDeep(this.transfer);
          transfer.splice(index, 1);
          payments = [this.cash, transfer, this.credits, this.installments];
          break;
        }
        case PAYMENT_TYPE.CREDIT: {
          const credits = cloneDeep(this.credits);
          credits.splice(index, 1);
          payments = [this.cash, this.transfer, credits, this.installments];
          break;
        }
        case PAYMENT_TYPE.INSTALLMENT: {
          const installments = cloneDeep(this.installments);
          installments.splice(index, 1);
          payments = [this.cash, this.transfer, this.credits, installments];
          break;
        }
        case PAYMENT_TYPE.REFUND: {
          const refunds = cloneDeep(this.refunds);
          refunds.splice(index, 1);
          refunds.map((item) => {
            const unMaskAmount = unMaskPrice(item.paymentAmount) * -1;
            item.paymentAmount = String(unMaskAmount);
            return item;
          });
          payments = [
            this.cash,
            this.transfer,
            this.credits,
            this.installments,
            refunds,
          ];
          break;
        }
      }
      return payments.flat().reduce((result, item) => {
        const unMask = unMaskPrice(item.paymentAmount || 0);
        return result + Number(unMask);
      }, 0);
    },
    bindingOutgoingMoney(index, type) {
      const sumCustomerPay = this.finalAmount || 0;
      const sumPayments = this.sumPaymentAmountByType(index, type);
      const remain = sumCustomerPay - sumPayments;
      if (remain > 0) {
        makeToastSuccess('Hoá đơn không thừa tiền không thể tự động điền');
        return;
      }
      switch (type) {
        case PAYMENT_TYPE.REFUND: {
          this.refunds[index].paymentAmount = Math.abs(remain);
          break;
        }
      }
    },
    bindingIncomingMoney(index, type) {
      const sumCustomerPay = this.finalAmount || 0;

      const sumPayments = this.sumPaymentAmountByType(index, type);
      const remain = sumCustomerPay - sumPayments;
      if (remain < 0) {
        return;
      }
      switch (type) {
        case PAYMENT_TYPE.CASH: {
          this.cash[index].paymentAmount = remain;
          break;
        }
        case PAYMENT_TYPE.TRANSFER: {
          this.transfer[index].paymentAmount = remain;
          break;
        }
        case PAYMENT_TYPE.CREDIT: {
          this.credits[index].paymentAmount = remain;
          break;
        }
        case PAYMENT_TYPE.INSTALLMENT: {
          this.installments[index].paymentAmount = remain;
          break;
        }
      }
    },
    onSelected(option, type) {
      switch (type) {
        case PAYMENT_TYPE.CASH:
          this.paymentInfo.cashAccountId = option.item.id;
          this.paymentInfo.cashAccountCode = option.item.code;
          this.paymentInfo.cashAccountantName = option.item.name;
          break;
        case PAYMENT_TYPE.TRANSFER:
          this.paymentInfo.transferAccountantId = option.item.id;
          this.paymentInfo.transferAccountCode = option.item.code;
          break;
        case PAYMENT_TYPE.CREDIT:
          this.paymentInfo.creditAccountantId = option.item.id;
          this.paymentInfo.creditAccountCode = option.item.code;
          break;
        case PAYMENT_TYPE.CREDIT_FEE:
          this.paymentInfo.creditFeeAccountantId = option.item.id;
          this.paymentInfo.creditFeeAcountCode = option.item.code;
          this.paymentInfo.creditFeeAccountantName = option.item.name;
          break;
        case PAYMENT_TYPE.INSTALLMENT: {
          this.paymentInfo.installMoneyAccountId = option.item.id;
          this.paymentInfo.installMoneyAccountCode = option.item.code;

          if (this.action === 'create' && option.item.id == SHINHAN_ID) {
            makeToastFaile(
              'Trả góp Shinhan bank không áp dụng cho tạo đơn hàng mới',
            );
            this.paymentInfo.installApplicationStatus = -1;
            this.paymentInfo.installMoneyAccountId = null;
            this.paymentInfo.installmentAccountName = null;
            this.filteredOptionsInstallment = [];
          }
          break;
        }
        case PAYMENT_TYPE.REFUND:
          this.paymentInfo.refundAccountId = option.item.id;
          this.paymentInfo.refundAccountCode = option.item.code;
          break;
      }
    },
    onSelectedMul(option, type) {
      if (!option) {
        return;
      }
      let payments = [];
      switch (type) {
        case PAYMENT_TYPE.INSTALLMENT: {
          payments = this.installments;
          break;
        }
        case PAYMENT_TYPE.CREDIT: {
          payments = this.credits;
          break;
        }
        case PAYMENT_TYPE.TRANSFER: {
          payments = this.transfer;
          break;
        }
        case PAYMENT_TYPE.CASH: {
          payments = this.cash;
          break;
        }
        case PAYMENT_TYPE.REFUND: {
          payments = this.refunds;
          break;
        }
      }
      payments[option.index].paymentRefId = option.item.id;
      payments[option.index].paymentRefCode = option.item.code;
      payments[option.index].paymentName = option.item.name;
      payments[option.index].accountBankNo = option.item.accountBankNo;
      payments[option.index].identifyCode =
        option.item.identifyCode || option.item.accountBankNo;
      payments[option.index].pendingTransactionId =
        option.item.pendingTransactionId;
      payments[option.index].pendingTransactionStatus =
        option.item.pendingTransactionStatus;
    },
    onInputChange(option, type) {
      switch (type) {
        case PAYMENT_TYPE.CASH: {
          this.filteredCashAccOptions = this.onFilterOptions(
            this.cashAccOptions,
            option.text,
          );
          if (this.isTextNul(option.text)) {
            this.paymentInfo.cashAccountId = null;
            //mul
            this.cash[option.index].paymentRefId = null;
            this.cash[option.index].paymentRefCode = null;
            this.cash[option.index].paymentName = null;
          }
          break;
        }
        case PAYMENT_TYPE.TRANSFER: {
          this.filteredTransferAccOptions = this.onFilterOptions(
            this.transferOptions,
            option.text,
          );
          if (this.isTextNul(option.text)) {
            this.paymentInfo.transferAccountantId = null;
            //mul
            this.transfer[option.index].paymentRefId = null;
            this.transfer[option.index].paymentRefCode = null;
            this.transfer[option.index].paymentName = null;
          }
          break;
        }
        case PAYMENT_TYPE.CREDIT: {
          this.filteredCreditAccOptions = this.onFilterOptions(
            this.creditOptions,
            option.text,
          );
          if (this.isTextNul(option.text)) {
            this.paymentInfo.creditAccountantId = null;
            //mul
            this.credits[option.index].paymentRefId = null;
            this.credits[option.index].paymentRefCode = null;
            this.credits[option.index].paymentName = null;
          }
          break;
        }
        case PAYMENT_TYPE.CREDIT_FEE: {
          this.filteredCreditFeeAccOptions = this.onFilterOptions(
            this.creditFeeAccOptions,
            option.text,
          );
          if (this.isTextNul(option.text)) {
            this.paymentInfo.creditFeeAccountantId = null;
            // this.credits[option.index].paymentFeeRefId = null;
            // this.credits[option.index].paymentFeeName = null;
          }
          break;
        }
        case PAYMENT_TYPE.INSTALLMENT: {
          this.filteredOptionsInstallment = this.onFilterOptions(
            this.optionsInstallment,
            option.text,
          );
          if (this.isTextNul(option.text)) {
            this.paymentInfo.installMoneyAccountId = null;
            this.installments[option.index].paymentRefId = null;
            this.installments[option.index].paymentRefCode = null;
            this.installments[option.index].paymentFeeName = null;
            this.installments[option.index].pendingTransactionId = null;
            this.installments[option.index].pendingTransactionStatus = null;
          }
          break;
        }
        case PAYMENT_TYPE.REFUND: {
          if (this.paymentInfo.refundOptionId === 1) {
            this.filteredOptionsRefunds = this.onFilterOptions(
              this.cashAccOptions,
              option.text,
            );
          } else if (this.paymentInfo.refundOptionId === 2) {
            this.filteredOptionsRefunds = this.onFilterOptions(
              this.transferOptions,
              option.text,
            );
          }

          if (this.isTextNul(option.text)) {
            this.paymentInfo.refundAccountId = null;
            //mul
            this.refunds[option.index].paymentRefId = null;
            this.refunds[option.index].paymentRefCode = null;
            this.refunds[option.index].paymentName = null;
          }
          break;
        }
      }
    },
    onFilterOptions(options, text) {
      const filteredData = options[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      return filteredData;
    },
    checkAmount(key) {
      return unMaskPrice(this.paymentInfo[key] || 0) > 0;
    },
    onCreditFeePayTypeChange(option) {
      if (!option) {
        this.paymentInfo.creditFeeAccountantId = null;
        this.paymentInfo.creditFeeAccountantName = '';
        this.creditFeeAccOptions[0].data = [];
      }

      if (this.paymentInfo.payCreditFeeType === payCreditFeeType.CASH) {
        this.creditFeeAccOptions[0].data = this.cashAccOptions[0].data;
      } else {
        this.creditFeeAccOptions[0].data = this.transferOptions[0].data;
      }
      this.filteredCreditFeeAccOptions = this.creditFeeAccOptions[0].data;
    },
    showLoanData(mode) {
      this.loanDataMode = mode;
      const prepaidAmount = [this.cash, this.transfer, this.credits]
        .flat()
        .reduce((result, item) => {
          const unMask = unMaskPrice(item.paymentAmount || 0);
          return result + Number(unMask);
        }, 0);

      const installmentAmount = [this.installments]
        .flat()
        .reduce((result, item) => {
          const unMask = unMaskPrice(item.paymentAmount || 0);
          return result + Number(unMask);
        }, 0);

      this.loanApplicationData = {
        orderId: this.billData.id,
        prepaidAmount: prepaidAmount,
        loanAmount: installmentAmount,
        phone: this.billData.customerMobile,
        email: '',
        fullName: this.billData.customerName,
      };
      setTimeout(() => {
        this.$refs['loan-data-modal'].showModal();
      }, TIME_TRIGGER);
    },
    checkInstallmentAmount() {
      return unMaskPrice(this.paymentInfo.installedMoneyAmount) > 0;
    },
    onCreateLoanSuccess(dataResponse) {
      if (dataResponse && dataResponse.applicationId) {
        this.$store.dispatch(SET_INSTALLMENT_SHINHAN, {
          installApplicationStatus: 1,
          loanApplicationId: dataResponse.applicationId,
        });
      }
    },
    fetchCashAcc: async function() {
      if (!this.billData.storeId) {
        return;
      }
      const params = {
        storeId: this.billData.storeId,
        //  tk tien mat
        type: 1,
      };
      ApiService.query('accountants/getByStore', { params }).then(
        (response) => {
          this.cashAccOptions[0].data = [];
          response.data.data.forEach((item) => {
            if (item.level >= 2) {
              const acc = {
                id: item.id,
                name: item.name,
                code: item.code,
              };
              this.cashAccOptions[0].data.push(acc);
            }
          });
          this.filteredCashAccOptions = this.cashAccOptions[0].data;

          if (this.paymentInfo.payCreditFeeType === payCreditFeeType.CASH) {
            this.creditFeeAccOptions[0].data = this.cashAccOptions[0].data;
            this.filteredCreditFeeAccOptions = this.creditFeeAccOptions[0].data;
          }
        },
      );
    },
    getCreditFee: async function() {
      await ApiService.query('settings/credit-fee').then((res) => {
        this.creditFeeRate = res.data.data;
      });
    },
    onCreditAmountChange() {
      const creditAmount = unMaskPrice(this.paymentInfo.creditAmount);
      if (!creditAmount) {
        this.paymentInfo.creditCardFee = 0;
      } else {
        this.paymentInfo.creditCardFee =
          (creditAmount * this.creditFeeRate) / 100;
      }
    },
    fetchInstallments: async function() {
      const params = {
        page: 1,
        pageSize: 1000,
      };
      await ApiService.query('/installments', { params }).then((response) => {
        this.optionsInstallment[0].data = response.data.data.result;
        this.filteredOptionsInstallment = this.optionsInstallment[0].data;
      });
    },
    fetchAccountant: async function() {
      const params = {
        //  tk ngan hang
        type: 2,
      };
      // reset data
      this.transferOptions[0].data = [];
      this.filteredTransferAccOptions = [];
      ApiService.query('accountants/getByType', { params }).then((response) => {
        response.data.data.forEach((item) => {
          if (item.level >= 2) {
            let identifyCode = '';
            if (item.subAccounts.length) {
              const subAccount = item.subAccounts.find(
                (sub) => sub.storeId === this.billData.storeId,
              );
              if (subAccount) identifyCode = subAccount.identifyCode;
            }
            const acc = {
              id: item.id,
              name: item.name,
              code: item.code,
              accountBankNo: item.accountBankNo,
              identifyCode: identifyCode,
            };
            this.transferOptions[0].data.push(acc);
          }
        });
        this.filteredTransferAccOptions = this.transferOptions[0].data;
        if (this.transfer.length) {
          for (let index = 0; index < this.transfer.length; index++) {
            const element = this.transfer[index];
            const transferItem = this.filteredTransferAccOptions.find(
              (acc) => Number(acc.id) === element.paymentRefId,
            );
            if (transferItem) {
              element.accountBankNo = transferItem.accountBankNo;
              element.identifyCode = transferItem.identifyCode;
            }
          }
        }
      });
    },
    fetchCreditAcc: async function() {
      const params = {
        //  tk quet the
        type: 3,
      };
      ApiService.query('accountants/getByType', { params }).then((response) => {
        //this.creditAccOptions[0].data = [];
        response.data.data.forEach((item) => {
          if (item.level >= 2) {
            const acc = {
              id: item.id,
              name: item.name,
              code: item.code,
            };
            this.creditOptions[0].data.push(acc);
          }
        });
        this.filteredCreditAccOptions = this.creditOptions[0].data;
      });
    },
    getDefaultValuePayment() {
      const defaultValue = {
        id: this.$uuid.v4(),
        paymentRefId: null,
        paymentRefCode: null,
        paymentName: null,
        paymentAmount: null,
        paymentCode: null,
        transactionId: null,
        pendingTransactionId: null,
        pendingTransactionStatus: null,
      };
      this.installments = [
        {
          ...defaultValue,
          customerIndentifyNo: null,
          loanApplicationId: null,
          installApplicationStatus: null,
          installmentAccountName: null,
          installmentProgramMonthNo: null,
          installmentProgramPrepayPercent: null,
          installmentProgramType: null,
          paymentType: PAYMENT_TYPE.INSTALLMENT,
          child: {
            ...defaultValue,
            paymentType: PAYMENT_TYPE.INSTALLMENT_FEE,
            paymentOptionType: null,
          },
        },
      ];
      this.credits = [
        {
          ...defaultValue,
          creditCardNo: null,
          payCreditFeeType: 1,
          creditCardFee: null,
          creditFeeAccountantName: null,
          paymentType: PAYMENT_TYPE.CREDIT,
        },
      ];
      this.transfer = [
        {
          ...defaultValue,
          paymentType: PAYMENT_TYPE.TRANSFER,
        },
      ];
      this.cash = [
        {
          ...defaultValue,
          paymentType: PAYMENT_TYPE.CASH,
        },
      ];
      this.refunds = [
        // should add needed
        // {
        //   ...defaultValue,
        //   paymentOptionType: null,
        //   paymentType: PAYMENT_TYPE.REFUND,
        // },
      ];
    },
    popupModalPayment() {
      this.$bvModal.show('modal-addition-payment');
    },
    cashBackSetting({ item, index }) {
      // mutilple cashback by index
      console.log('Popup cashback: ', item, index);
      alert('Popup cashback', JSON.stringify(item) + index);
    },
    onChangeCheckBox(option) {
      switch (option.paymentType) {
        case PAYMENT_TYPE.REFUND: {
          this.filteredOptionsRefunds = [];
          this.paymentInfo.refundAccountId = null;
          this.refunds[option.index].paymentRefId = null;
          this.refunds[option.index].paymentRefCode = null;
          this.refunds[option.index].paymentName = null;
          if (option.value === 'TM') {
            this.filteredOptionsRefunds = this.filteredCashAccOptions;
          } else if (option.value === 'CK') {
            this.filteredOptionsRefunds = this.filteredTransferAccOptions;
          }
          break;
        }
      }
    },
    showApprovalModal(item) {
      const data = {
        amount: item.paymentAmount,
        customerId: this.fromOrder
          ? this.billData.customerId
          : this.billData.customerModel.customerId,
        customerName: this.fromOrder
          ? this.billData.customerName
          : this.billData.customerModel.customerName,
        customerMobile: this.fromOrder
          ? this.billData.customerMobile
          : this.billData.customerModel.customerMobile,
        storeId: this.billData.storeId,
        paymentType: item.paymentOptionType === 'TM' ? 1 : 2,
      };
      const approvalType = this.fromOrder
        ? APPROVAL_TYPE_ENUM.DEPOSIT
        : APPROVAL_TYPE_ENUM.OVER_PAYMENT;
      const entityId = this.fromOrder
        ? this.billData.id
        : this.billData.billNumber;
      this.$refs['popup-approval'].showModal(entityId, data, approvalType);
    },
    async onChangeInterest() {
      const changeInterest = !this.isInterestZero;
      const interestText = changeInterest ? 'trả góp 0%' : 'trả lãi';

      const choice = await this.popupSwalWarning({
        title: `Chuyển sang ${interestText}`,
        text: `Bạn muốn chuyển trạng thái ${interestText}`,
      });
      switch (choice.isConfirmed) {
        case true: {
          if (changeInterest) {
            const products = this.handleOrderItemUpsert(
              cloneDeep(this.getProducts),
            );
            if (products.length > 1) {
              this.popupSwalError({
                title: `Chương trình trả góp 0%`,
                text: `Chỉ áp dụng cho 1 sản phẩm`,
              });
              return;
            }
          }
          this.isInterestZero = changeInterest;
          break;
        }
      }
      this.$store.dispatch(SET_INTEREST, this.isInterestZero);
    },
    async onChangeShinhan() {
      const changeInterest = !this.isInterestShinhan;
      const interestText = changeInterest ? 'duyệt hồ sơ shinhan' : 'trả lãi';

      const choice = await this.popupSwalWarning({
        title: `Chuyển sang ${interestText}`,
        text: `Bạn muốn chuyển trạng thái ${interestText}`,
      });

      switch (choice.isConfirmed) {
        case true: {
          this.isInterestShinhan = changeInterest;
          this.onLoading.shinhan = true;
          break;
        }
      }
    },
    onShowInterestProgram() {
      this.onLoading.interestZero = true;
      this.$nextTick(() => {
        EventBus.$emit('popup-insterest-program');
      });
    },
    showInstallmentKredivoOptions(item) {
      return (
        this.isUpdateBill &&
        this.isEnableInstallmentKredivo &&
        item.paymentAmount > 0 &&
        item.paymentRefCode === INSTALLMENT_CODE.KREDIVO_OFFLINE
      );
    },
  },
};
</script>

<style lang="scss">
.payment-common {
  .qr-code-checkbox {
    display: flex;
    flex-direction: row;
  }
  .account-bank-no {
    margin-left: auto;
    margin-bottom: 0;
  }
  .generate-qr-button {
    width: -webkit-fill-available;
    margin-top: 4px;
    margin-bottom: 4px;
    font-weight: 500;
  }
  .btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
  }
  .btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
  }
}
</style>
