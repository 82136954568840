<template>
  <div class="create-user-access-ip">
    <KTCodePreview v-bind:title="pageTitle">
      <template v-slot:toolbar> </template>
      <template v-slot:preview>
        <div v-if="isType('ip')">
          <ChildUpsertIp :id="id" />
        </div>
        <div v-if="isType(['store', 'jobTitle', 'employee'])">
          <ChildUpsertRole :id="id" :type="type" />
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import ChildUpsertIp from './ChildUpsertIp';
import ChildUpsertRole from './ChildUpsertRole';

export default {
  mixins: [validationMixin],
  data() {
    return {
      pageTitle: 'Tạo cấu hình ip',
    };
  },
  components: {
    KTCodePreview,
    ChildUpsertIp,
    ChildUpsertRole,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ pageTitle: this.pageTitle }]);
  },
  created() {
    this.id = this.$route.params.id || null;
    this.type = this.$route.params.type || 'store';
  },
  methods: {
    isType(type) {
      if (Array.isArray(type)) {
        return type.includes(this.type);
      }
      return this.type === type;
    },
  },
};
</script>