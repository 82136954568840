<template>
  <div>
    <KTCodePreview v-bind:title="'In bảng giá sản phẫm'">
      <template v-slot:toolbar>
        <b-button
          type="button"
          class="btn btn-primary font-weight-bolder btn-sm"
          @click="printData"
          style="width: max-content"
        >
          <i class="fas fa-print"></i>
          In bảng giá
        </b-button>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <div id="printMe">
          <div
            class="d-flex"
            v-for="(group, index) in listProduct"
            :key="index"
          >
            <div
              class="item-print"
              v-for="(product, count) in group"
              :key="count"
              style="background: #ffffff"
            >
              <img src="media/logos/vdd_logo.png" alt="" class="img" />

              <div class="content">
                <p class="item-name content-info mt-0">
                  {{ product.productName }}
                </p>
              </div>
              <div class="content">  
                <p class="item-price content-info">
                  {{ convertPrice(product.sellingPrice) }} VNĐ
                </p>
              </div>  
                <b-row v-if="product.listedPrice - product.sellingPrice > 0">
                  <b-col class="px-0">
                    <div class="box">
                      <div class="element-center text-print">
                        <p>Giảm ngay</p>
                        <p>
                          {{
                            convertPrice(
                              product.listedPrice - product.sellingPrice
                            )
                          }} đ
                        </p>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="px-0 pt-5">
                    <p class="content-info listed-price">
                      Giá niêm yết
                    </p>
                    <p
                      class="content-info item-name"
                      style="text-decoration: line-through"
                    >
                      {{ convertPrice(product.listedPrice) }} vnđ
                    </p>
                  </b-col>
                </b-row>
             
              <div class="bottom">
                <div class=" display-flex">
                  <div class="col-flex" style="background: black; color: white">
                    <span
                      ><i class="fa fa-globe text-white"></i
                    ></span>
                    didongviet
                  </div>
                  <div class="col-flex" style="color: black">
                    <i class="fa fa-phone" style="color: black"></i> 1800.6018
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { makeToastFaile } from '@/utils/common';
import { TIME_TRIGGER } from '@/utils/constants';

export default {
  props: ['data'],
  data() {
    return {
      listProduct: [],
      isMargin: false,
      TYPE:{
        BARCODE_3: 1,
        BARCODE_2: 2,
        PRODUCT_PRICE: 3,
      }
    };
  },
  created() {
    if (!this.data) {
      return makeToastFaile('Chưa có dữ liệu!');
    }
    this.genData();
  },
  methods: {
    printData: async function () {
      if (!document.getElementById('printMe')) {
        return;
      }
      const prtHtml = document.getElementById('printMe').innerHTML;

      const width = 210;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0'
      );

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <link rel="stylesheet" href="main.css" />
          <link rel="stylesheet" media="print" href="print.css" />
          ${stylesHtml}
          <style>
          @page {
            size: ${width}mm 234mm;
            margin: 0;
            }
          @media print {
            html,
            body {
              width: ${width}mm;
              display: block !important;
              line-height: 1;
            }
            .img {
              width: 90px;
              margin-left: 5px;
            }
            .box {
              width: 240px;
              height: 85px;
              position: relative;
              color: white;
              text-align: center;
              aspect-ratio: 1;
              background: black;
              clip-path: polygon(
                100% 50%,
                78.98% 57.76%,
                93.3% 75%,
                71.21% 71.21%,
                75% 93.3%,
                57.76% 78.98%,
                50% 100%,
                42.24% 78.98%,
                25% 93.3%,
                28.79% 71.21%,
                6.7% 75%,
                21.02% 57.76%,
                0% 50%,
                21.02% 42.24%,
                6.7% 25%,
                28.79% 28.79%,
                25% 6.7%,
                42.24% 21.02%,
                50% 0%,
                57.76% 21.02%,
                75% 6.7%,
                71.21% 28.79%,
                93.3% 25%,
                78.98% 42.24%
              );
            }
            .element-center {
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              position: absolute;
            }

            p {
              margin: 0 !important;
            }
            .content-info {
              color: black;
              text-align: center;
            }

            .text-print {
              font-size: 1.3rem !important;
              font-weight: 600;
              text-align: center;
            }
            .bottom {
              border-top: 2px solid black;
            }

            .item-price {
              font-size: 44px !important;
              font-weight: 700;
            }
            .item-name {
              font-weight: 600;
              font-size: 25px !important;
            }
            .content {
              padding: 5px 0 0 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-direction: column;
            }
            .display-flex {
              display: flex;
              width: 100%;
            }
            .col-flex {
              width: 50%;
            }
            .item-print {
              width: 455px !important;
              line-height: 1;
              height: 260px;
              border: 2px solid black;
              padding-top: 5px;
              margin: 0 0 20px 20px;
              position: relative;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            }
            #printMe {
              display: flex;
              flex-wrap: wrap;
              /* padding: 100px 0; */
            }
                      }
          </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
      }, TIME_TRIGGER);
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    genData() {
      const limit = this.data.type === this.TYPE.BARCODE_3 ? this.TYPE.PRODUCT_PRICE : this.TYPE.BARCODE_2;
      const listItem = this.data.listItem ? this.data.listItem : [];
      const listProductPrint = [];
      listItem.forEach((element) => {
        const quantity = parseInt(element.quantity);
        for (let index = 0; index < quantity; index++) {
          const barCodeHeight = element.productName.length < 50 ? 28 : 20; //responsive height barcode
          const item = {
            id: element.id,
            barCode: element.barCode,
            productName: element.productName,
            listedPrice: element.listedPrice,
            sellingPrice: element.sellingPrice,
            barCodeHeight: barCodeHeight,
          };
          listProductPrint.push(item);
        }
      });
      const numPage = Math.ceil(listProductPrint.length / limit);
      this.listProduct = [];
      for (let page = 1; page <= numPage; page++) {
        const startIndex = limit * (page - 1);
        const endIndex = page * limit;
        const groupItem = listProductPrint.slice(startIndex, endIndex);
        this.listProduct.push(groupItem);
      }

      this.printData();
    },
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'In bảng giá' },
    ]);
  },
};
</script>

<style scoped>
.img {
  width: 90px;
  margin-left: 5px;
}
.box {
  width: 240px;
  height: 85px;
  position: relative;
  color: white;
  text-align: center;
  aspect-ratio: 1;
  background: black;
  clip-path: polygon(
    100% 50%,
    78.98% 57.76%,
    93.3% 75%,
    71.21% 71.21%,
    75% 93.3%,
    57.76% 78.98%,
    50% 100%,
    42.24% 78.98%,
    25% 93.3%,
    28.79% 71.21%,
    6.7% 75%,
    21.02% 57.76%,
    0% 50%,
    21.02% 42.24%,
    6.7% 25%,
    28.79% 28.79%,
    25% 6.7%,
    42.24% 21.02%,
    50% 0%,
    57.76% 21.02%,
    75% 6.7%,
    71.21% 28.79%,
    93.3% 25%,
    78.98% 42.24%
  );
}
.listed-price {
  margin: 0 0 10px;
  font-size: 1.5rem;
}
.bottom {
  font-size: 30px;
  font-weight: 600;
}
.element-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

 p {
  margin: 0 !important;
}
.content-info {
  color: black;
  text-align: center;
}

.text-print {
  font-size: 1.3rem !important;
  font-weight: 600;
  text-align: center;
}
.bottom {
  border-top: 2px solid black;
  font-size: 1.5rem !important;
  font-weight: 600;
  text-align: center;
}

.item-price {
  font-size: 44px !important;
  font-weight: 700;
  /* margin-bottom: 3px !important; */
}
.item-name {
  font-weight: 600;
  font-size: 26px !important;
}
.content {
  padding: 5px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.display-flex {
  display: flex;
  width: 100%;
}
.col-flex {
  width: 50%;
  /* padding: 5px; */
}
.item-print {
  width: 455px !important;
  line-height: 1;
  height: 260px;
  border: 2px solid black;
  padding-top: 5px;
  margin: 0 0 20px 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
#printMe {
  display: flex;
  flex-wrap: wrap;
  /* padding: 100px 0; */
}
</style>
