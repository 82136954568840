<template>
  <div>
    <KTCodePreview v-bind:title="'Lịch sử xuất excel'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <router-link to="/report-excel/chart">
              <b-button
                size="sm"
                variant="primary"
              >
                <i
                  style="font-size: 1rem"
                  class="fas fa-chart-line"
                ></i>
                <b>Xem thống kê</b>
              </b-button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-form>
            <b-row class="mb-5">
              <b-col
                md="3"
                class="autosugges-container"
              >
                <Autosuggest
                  :model="searchEmployee"
                  :suggestions="resultQuery"
                  :placeholder="'Nhập tên nhân viên'"
                  :limit="10"
                  @select="onSelectedEmployee"
                  @change="onInputChangeEmployee"
                >
                  <template #custom="{ suggestion }">
                    <div>
                      <span>{{ suggestion.item.fullName }}</span>
                    </div>
                  </template>
                </Autosuggest>
              </b-col>
              <b-col md="3">
                <treeselect
                  v-model="apiParams.moduleType"
                  :options="listModule"
                  :multiple="true"
                  :flatten-search-results="true"
                  :match-keys="['label', 'labelCustoms']"
                  placeholder="Tìm kiếm theo module"
                  noResultsText="Không tìm thấy module"
                />
              </b-col>
              <b-col>
                <div class="d-flex justify-content-center align-items-center">
                  <date-picker
                    placeholder="Từ ngày"
                    class="form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.fromDate"
                  ></date-picker>
                  <span class="mr-1 ml-1"></span>
                  <date-picker
                    placeholder="Đến ngày"
                    class="form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.toDate"
                  ></date-picker>
                </div>
              </b-col>
              <b-col md="2">
                <b-form-select
                  size="sm"
                  required
                  class="select-style"
                  placeholder="Hiển thị"
                  v-model="apiParams.limit"
                  :options="listSize"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  filter
                ></b-form-select>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col
                md="1"
                style="padding-left: 0"
              >
                <b-overlay
                  :show="isBusy"
                  opacity="0.6"
                >
                  <b-button
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    @click="fetchReportHistory()"
                  >Lọc</b-button>
                </b-overlay>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <!-- End of Header session -->
        <b-row>
          <b-col cols="12">
            <b-table
              :items="reportHistories"
              :fields="fields"
              bordered
              :busy="isBusy"
            >
              <template v-slot:table-busy>
                <vcl-table
                  :speed="5"
                  :animate="true"
                  :columns="10"
                ></vcl-table>
              </template>
              <template #cell(index)="row">
                {{ getIndexDataTable(row.index + 1, currentPage, perPage) }}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-space-between">
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              align="right"
              :link-gen="linkGen"
              v-show="totalPages >= 2"
              :number-of-pages="totalPages"
              use-router
              @change="fetchReportHistory"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scoped>
.autosugges-container {
  margin: 0;
  padding: 0;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {
  makeToastFaile,
  xoa_dau,
  cloneDeep,
  removeAccents,
} from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { VclTable } from 'vue-content-loading';
import { MODULE_TYPE } from '@/utils/enum';
import {
  DP_CONFIG,
  getSubtractDate,
  formatSpecificDate,
  DATE_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
} from '@/utils/date';

export default {
  components: {
    KTCodePreview,
    Autosuggest,
    VclTable,
    Treeselect,
  },
  data() {
    return {
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'index',
          label: 'STT',
          tdClass: 'text-center',
          thStyle: { color: '#181c32', width: '10%' },
          thClass: 'text-center',
        },
        {
          key: 'userName',
          label: 'Tên tài khoản',
          thStyle: { color: '#181c32', width: '20%' },
        },
        {
          key: 'employeeName',
          label: 'Tên nhân viên',
          thStyle: { color: '#181c32', width: '20%' },
        },
        {
          key: 'jobTitleName',
          label: 'Chức vụ',
          thStyle: { color: '#181c32', width: '20%' },
        },
        {
          key: 'moduleName',
          label: 'Module',
          thStyle: { color: '#181c32', width: '20%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày xuất',
          thStyle: { color: '#181c32', width: '20%' },
        },
      ],
      reportHistories: [],
      currentPage: 1,
      apiParams: {
        moduleType: null,
        toDate: null,
        fromDate: getSubtractDate(15),
        employeeId: null,
        limit: null,
      },
      listModule: [
        { id: MODULE_TYPE.CUSTOMER, label: 'Khách hàng' },
        { id: MODULE_TYPE.ORDER, label: 'Đơn hàng' },
        { id: MODULE_TYPE.BILL, label: 'Bán hàng' },
        { id: MODULE_TYPE.PRODUCT, label: 'Sản phẩm' },
        { id: MODULE_TYPE.PRODUCT_IMEI, label: 'Sản phẩm IMEI' },
        { id: MODULE_TYPE.TRADE_IN, label: 'Thu cũ' },
        { id: MODULE_TYPE.STOCK, label: 'Kho' },
        { id: MODULE_TYPE.PRE_ORDER, label: 'Đơn đặt trước' },
      ],
      listSize: [
        { id: null, name: 'Hiển thị' },
        { id: 25, name: '25' },
        { id: 50, name: '50' },
        { id: 100, name: '100' },
      ],
      dpConfigs: DP_CONFIG,
      totalItems: 0,
      totalPages: 1,
      perPage: 10,
      filteredOptionsEmployee: [],
      filteredOptionsOriginal: [],
      searchEmployee: '',
      isBusy: false,
    };
  },
  created() {
    this.fetchReportHistory();
    this.fetchEmployees();
    this.customLabelModuleName();
  },
  computed: {
    resultQuery() {
      if (this.searchEmployee) {
        return cloneDeep(this.filteredOptionsOriginal).filter((item) => {
          return (
            removeAccents(item.fullName)
              .toLowerCase()
              .indexOf(removeAccents(this.searchEmployee.toLowerCase())) > -1
          );
        });
      } else {
        return this.filteredOptionsOriginal;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Lịch sử xuất excel',
        route: 'report-excel',
      },
    ]);
  },
  methods: {
    viewDetail(id) {
      console.log('id::: ', id);
    },
    customLabelModuleName() {
      this.listModule = this.listModule.map((item) => ({
        ...item,
        labelCustoms: removeAccents(item.label),
      }));
    },
    async fetchReportHistory() {
      try {
        if (this.isBusy) return;
        this.isBusy = true;
        this.currentPage = this.$route.query.page;
        const params = {
          ...this.apiParams,
          page: this.currentPage,
          fromDate: formatSpecificDate(
            this.apiParams.fromDate,
            DATE_FORMAT,
            YEAR_MONTH_DAY_FORMAT,
          ),
          toDate: formatSpecificDate(
            this.apiParams.toDate,
            DATE_FORMAT,
            YEAR_MONTH_DAY_FORMAT,
          ),
        };
        const response = (await ApiService.query('report-excel', { params }))
          .data.data;
        this.reportHistories = response.result;
        this.totalItems = response.totalItems;
        this.currentPage = response.currentPage;
        this.totalPages = response.totalPages || 1;
      } catch (err) {
        const message = err.response?.data?.message || 'Network Error';
        return makeToastFaile(message);
      } finally {
        this.isBusy = false;
      }
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item?.fullName;
      this.apiParams.employeeId = option.item?.id;
    },
    onInputChangeEmployee(text) {
      this.searchEmployee = text;
      this.apiParams.employeeId = null;
    },
    async fetchEmployees() {
      try {
        const params = {
          orderBy: ['createdAt', 'DESC'],
        };
        const response = await ApiService.query('employees/getAll', params);
        const employees = response.data.data || [];
        const employeeOptions = employees.map((item) => {
          const { id, fullName, code, email } = item;
          const nameNoneSign = xoa_dau(fullName);
          return {
            id,
            code,
            email,
            fullName: nameNoneSign,
            nameNoneSign: nameNoneSign,
          };
        });
        this.filteredOptionsOriginal = employeeOptions;
      } catch (err) {
        const message = err.response?.data?.message || 'Network Error';
        return makeToastFaile(message);
      }
    },
    getIndexDataTable(index, currentPage, perPage) {
      return index + (currentPage - 1) * perPage;
    },
  },
};
</script>