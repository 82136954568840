<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <KTCodePreview v-bind:title="'Danh sách nhân sự'">
      <template v-slot:toolbar>
        <div class="row" v-if="checkPermission('EMPLOYEE_INSERT')">
          <b-dropdown size="sm" id="dropdown-right" right variant="primary">
            <template slot="button-content">
              <i style="font-size: 1rem" class="flaticon2-add-1"></i>
              <span class="font-weight-bolder">Tạo mới nhân sự</span>
            </template>
            <b-dropdown-item>
              <router-link to="/employees/upsert-employee">
                <span style="color: #3f4254; width: 100%">
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                  &nbsp; Tạo nhân sự
                </span>
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link to="/employees/import-employee">
                <span style="color: #3f4254">
                  <i style="font-size: 1rem" class="la la-file-excel-o"></i>
                  &nbsp; Nhập từ file excel
                </span>
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <div class="col-md-12 mb-5">
          <b-row>
            <b-col md="3" class="pl-0">
              <b-input
                size="sm"
                placeholder="Nhập tên nhân sự"
                v-model="search"
                append-icon="search"
                single-line
                hide-details
                v-on:keyup.enter="onFilter()"
              ></b-input>
            </b-col>
            <b-col md="3">
              <vue-autosuggest
                v-model="searchStock"
                :suggestions="filteredOptions"
                @selected="onSelected"
                :limit="10"
                @input="onInputChange"
                :input-props="{
                  id: 'autosuggest__input',
                  placeholder: 'Nhập tên cửa hàng',
                }"
              >
                <div
                  slot-scope="{ suggestion }"
                  style="display: flex; align-items: center"
                >
                  {{ suggestion.item.suggestionName }}
                </div>
              </vue-autosuggest>
            </b-col>
            <b-col md="3">
              <div class="row">
                <b-form-select
                  size="sm"
                  v-model="selectedJobTitle"
                  :options="listJobTitle"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </div>
            </b-col>
            <div class="col-md-8" style="padding-right: 0px">
              <div class="d-flex justify-content-end"></div>
            </div>
          </b-row>
          <b-row class="mt-5">
            <b-col md="1" class="pl-0">
              <b-button
                style="fontweight: 600; width: 70px"
                variant="primary"
                size="sm"
                @click="onFilter()"
                >Lọc</b-button
              >
            </b-col>
          </b-row>
        </div>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="employees"
          :fields="fields"
          sort-by="count"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span v-text="row.item.count" class="text-right"></span>
            </div>
          </template>
          <template v-slot:cell(code)="row">
            <a v-bind:href="getLinkDoc(row.item)">
              <span>
                {{ row.item.code }}
              </span>
            </a>
          </template>
          <template v-slot:cell(fullName)="row">
            <div class="font-weight-bolder">
              <span v-text="row.item.fullName" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(jobTitleName)="row">
            <div>
              <span v-text="row.item.jobTitleName" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(email)="row">
            <div>
              <span v-text="row.item.email" class="text-right"></span>
            </div>
          </template>

          <template v-slot:cell(storeName)="row">
            <div>
              <span
                v-text="row.item.storeName"
                class="font-weight-bolder"
              ></span>
              <br />
              <span
                v-if="row.item.listStoreName"
                v-text="row.item.listStoreName"
                style="font-size: 11px"
              ></span>
            </div>
          </template>
          <template v-slot:cell(isActive)="row">
            <i
              v-bind:class="{
                'fas fa-check text-success': row.item.isActive == 1,
                'fas fa-minus-circle text-danger': row.item.isActive != 1,
              }"
            />
          </template>

          <template v-slot:cell(mobile)="row">
            <div style="text-align: center">
              <span v-text="row.item.mobile" class="text-right"></span>
            </div>
          </template>
          <template v-slot:cell(birthDay)="row">
            <div style="text-align: center">
              <span
                v-text="row.item.birthDay"
                class="text-right text-primary mb-0"
              ></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('EMPLOYEE_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số:
              {{ formatMoney(totalItems) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchEmployee"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import decounce from 'debounce';
import { VclTable } from 'vue-content-loading';
import { TIME_TRIGGER } from './../../../utils/constants';
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  data() {
    return {
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      isSearching: false,
      storeName: '',
      currentPage: 0,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      search: '',
      employees: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'code',
          label: 'Mã nhân sự',
          sortable: false,
          thStyle: { width: '10%' },
        },
        {
          key: 'fullName',
          label: 'Tên nhân sự',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'jobTitleName',
          label: 'Chức vụ',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'departmentName',
          label: 'Bộ phận',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'storeName',
          label: 'Cửa hàng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'isActive',
          label: 'Hoạt động',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-center',
        },
        { key: 'actions', label: '' },
      ],
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      limit: 10,
      searchStock: '',
      listJobTitle: [],
      selectedJobTitle: null,
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    VueAutosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Nhân sự', route: 'employees' },
      { title: 'Danh sách nhân sự' },
    ]);
  },
  created() {
    this.fetchEmployee();
    this.fetchStore();
    this.fetchJobTitles();
  },
  methods: {
    fetchJobTitles: async function () {
      ApiService.query('job-title-config/job-titles').then((response) => {
        this.listJobTitle = [...response.data.data];
        this.listJobTitle.unshift({ id: null, name: 'Hãy chọn chức vụ' });
      });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'upsert-employee',
        query: { id: item.id },
      });
    },
    onFilter() {
      this.isSearching = true;
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-employee',
      });
      this.fetchEmployee();
    },
    fetchEmployee: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;

      if (!this.page) {
        this.page = 1;
      }

      if (!this.search) {
        this.search = '';
      }

      if (!this.searchStock) {
        this.selected = null;
      }

      const param = {
        page: this.page,
        pageSize: 10,
        fullName: this.search,
        jobTitleId: this.selectedJobTitle,
        storeId: this.selected,
      };

      const paramQuery = {
        params: param,
      };

      ApiService.query('employees', paramQuery).then((response) => {
        const dataRes = response.data.data;
        this.employees = [];
        this.totalPages = dataRes.totalPages > 0 ? dataRes.totalPages : 1;
        this.totalItems = dataRes.totalItems;
        this.currentPage = dataRes.currentPage;
        this.employees = dataRes.employees.map((item, index) => {
          return {
            count:
              this.currentPage >= 2
                ? index + 1 + this.currentPage * 10 - 10
                : index + 1,
            ...item,
          };
        });
        this.isSearching = false;
        this.onLoading = false;
      });
    },
    showDeleteSuccess: function () {
      Swal.fire({
        title: 'Thông báo',
        text: 'Xóa nhân viên thành công!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      });
    },
    deleteItem: async function (item) {
      ApiService.patch('employees' + '/' + item.id).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchEmployee();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa nhân viên!',
        text: 'Bạn có chắc muốn xóa nhân viên này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('EMPLOYEE_VIEW')) {
        count++;
      }
      if (localData.checkPermission('EMPLOYEE_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    debounceInput: decounce(function () {
      this.searchEmployee();
    }, TIME_TRIGGER),
    onSelected(option) {
      this.selected = option.item.id;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;
      // if (text === '' || text === undefined) {
      //   return;
      // }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
      //this.fetchStock();
    },
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        this.filteredOptions = [{ data: this.options[0].data }];
      });
    },
    formatMoney(number) {
      if (!number) return 0;
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    getLinkDoc(item) {
      return `#/employees/upsert-employee?id=${item.id}`;
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped>
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.productCode:hover {
  text-decoration: underline;
}
</style>
<style>
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
