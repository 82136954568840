<template>
  <div id="printMe">
    <div class="container">
      <!-- Header -->
      <div class="header">
        <div class="logo">
          <img
            src="media/logos/vdd_hori_logo.png"
            alt="vdd-logo"
          />
        </div>

        <div class="order-info">
          <h2 class="font-size-large">PHIẾU ĐẶT HÀNG</h2>
          <div class="order-detail">
            <div><span class="font-size-print text-bold-500">Số:</span> <span class="font-size-print">{{ orderData.id }}</span></div>
            <div><span class="font-size-print text-bold-500">Ngày nhận:</span> <span class="font-size-print">{{ orderData.checkDate }}</span></div>
          </div>
        </div>
      </div>
      <!-- Thông tin công ty - khách hàng -->
      <div class="information">
        <div class="company">
          <p class="font-size-print">
            <span class="text-bold-500">Chi nhánh:</span> {{ orderData.storeName }}
          </p>
          <p class="font-size-print"><span class="text-bold-500">Hotline:</span> 1800 6018</p>
          <p class="font-size-print">
            <span class="text-bold-500">Địa chỉ:</span> {{ orderData.storeAddress }}
          </p>
        </div>
        <div class="customer">
          <p class="font-size-print"><span class="text-bold-500">Tên khách hàng:</span> {{ orderData.customerName }}</p>
          <p class="font-size-print"><span class="text-bold-500">Số điện thoại:</span> {{ orderData.customerMobile }}</p>
          <p class="font-size-print">
            <span class="text-bold-500">Địa chỉ:</span> {{ orderData.customerAddress }}
          </p>
        </div>
      </div>
      <!-- Danh sách sản phẩm -->
      <div class="products">
        <table>
          <thead>
            <tr>
              <th
                class="text-center"
                style="width: 5%"
              >
                <span class="font-size-print">STT</span>
              </th>
              <th>
                <span class="font-size-print">Tên sản phẩm</span>
              </th>
              <th><span class="font-size-print">SL</span></th>
              <th class="text-right"><span class="font-size-print">Đơn giá</span></th>
              <th class="text-right"><span class="font-size-print">CK</span></th>
              <th class="text-right">
                <span class="font-size-print">Thành tiền</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(product, index) in orderData.orderItems">
              <tr :key="product.id">
                <td class="text-center">
                  <span
                    class="font-size-print text-bold-500"
                    v-if="product.orderItemType === 1"
                  >{{ index + 1 }}</span>
                </td>
                <td>
                  <span
                    class="font-size-print"
                    v-if="product.orderItemType === 2"
                  ><i
                      data-v-f69b43ce=""
                      class="fas fa-gift ml-4"
                      style="color: rgb(24, 28, 50);"
                    ></i> {{ product.productName }}</span>
                  <span
                    v-else
                    class="font-size-print"
                  >{{ product.productName }}</span>
                </td>
                <td class="text-center">
                  <span class="font-size-print">{{ product.quantity }}</span>
                </td>
                <td class="text-right">
                  <span class="font-size-print">{{ convertPrice(product.productPrice) }}</span>
                </td>
                <td class="text-right">
                  <span class="font-size-print">{{ convertPrice(product.discountAmount) }}</span>
                </td>
                <td class="text-right">
                  <span
                    class="font-size-print"
                    v-if="product.orderItemType === 1"
                  >{{ convertPrice(product.totalAmount) }}</span>
                  <span
                    v-else
                    class="font-size-print"
                  >0</span>
                </td>
              </tr>
            </template>
          </tbody>

          <tfoot>
            <tr>
              <td colspan="2">
                <div>
                  <span class="font-size-print text-bold-500">Số tiền đã thanh toán:</span>
                  <span class="font-size-print"> {{ convertPrice(orderData.sumPayment) }}</span>
                </div>
                <div>
                  <span class="font-size-print text-bold-500">Số tiền còn lại phải thanh toán:</span>
                  <span class="font-size-print"> {{ convertPrice(orderData.totalAmountToPay) }}</span>
                </div>
                <div>
                  <span class="font-size-print text-bold-500">Tổng chiết khấu:</span>
                  <span class="font-size-print"> {{ convertPrice(orderData.discountAmount) }}</span>
                </div>
              </td>
              <td class="text-center">
                <span class="font-size-print">{{ orderData.totalQuantity }}</span>
              </td>
              <td class="text-right">
                <span class="font-size-print">{{ convertPrice(orderData.totalUnitPrice) }}</span>
              </td>
              <td class="text-right">
                <span class="font-size-print">{{ convertPrice(orderData.totalDiscountAmount) }}</span>
              </td>
              <td class="text-right">
                <span class="font-size-print text-bold-500"> {{ convertPrice(orderData.totalAmount) }}</span>
              </td>
            </tr>
            <tr>
              <td colspan="8">
                <p class="font-size-print"><span class="text-bold-500">Bằng chữ:</span> {{ convertNumberToText(orderData.totalAmount) }}</p>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <!-- Ghi chú -->
      <div class="note">
        <p class="font-size-print">
          <span class="text-bold-500">Ghi chú: </span>{{ orderData.customerNote }}
        </p>
      </div>
      <!-- Kí kết -->
      <div class="signing">
        <div class="sign-customer">
          <span class="font-size-print text-bold-500">Khách hàng</span>
        </div>
        <div class="sign-sale">
          <span class="font-size-print text-bold-500">Nhân viên bán hàng</span>
        </div>
        <div class="sign-accountant">
          <span class="font-size-print text-bold-500">Kế toán</span>
        </div>
      </div>
      <!-- Footer -->
      <footer>
        <p class="font-size-print"><i
            style="color: #fff;"
            class="fa-solid fa-phone"
          ></i> 1800.6729</p>
        <p class="font-size-print"><i
            style="color: #fff;"
            class="fa-solid fa-earth-americas"
          ></i> viendidong.com.</p>
        <p class="font-size-print"><i
            style="color: #fff;"
            class="fa-solid fa-envelope"
          ></i> lienhe@viendidong.com</p>
      </footer>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/core/services/event-bus';
import { convertPrice, convertNumberToText } from '@/utils/common';

export default {
  props: ['payload'],
  components: {},
  mounted() {
    EventBus.$on('order-fetched', (data) => {
      this.orderData = data;
    });
  },
  data() {
    return {
      convertNumberToText,
      convertPrice,
      orderData: {},
    };
  },
};
</script>

<style lang="css" scoped>
/* ============ common ============ */
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
  print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
}
.container {
  --primary-color: #000000;
  position: relative;
  height: 100%;
}

.container::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  height: 96%;
  opacity: 0.2;
  background-image: url(/media/logos/vdd_1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-size-large {
  font-size: 26px;
  color: var(--primary-color);
}

.font-size-print {
  color: var(--primary-color);
  font-size: 20px;
}

.text-bold-500 {
  font-weight: 500;
}

.text-bold-400 {
  font-weight: 400;
}

/* ============ heading ============ */
.header {
  height: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header .logo {
  flex: 1;
}

.header .order-info {
  flex: 1;
  display: inline-block;
  text-align: center;
}

.header .order-info .order-detail {
  display: flex;
  justify-content: center;
  gap: 60px;
}

.logo img {
  object-fit: contain;
  max-width: 100%;
}

/* ============ order info ============ */
.order-info {
  display: flex;
  justify-content: center;
}

/* ============ information ============ */
.information {
  height: fit-content;
  display: flex;
  gap: 20px;
}

.information .company {
  flex-basis: calc(50% - 20px);
  border: 0.5px solid black;
  border-radius: 4px;
  padding: 10px;
}

.information .customer {
  flex-basis: 50%;
  border: 0.5px solid black;
  border-radius: 4px;
  padding: 10px;
}

.information p {
  margin-bottom: 0px;
  line-height: 1.4;
}

.products {
  margin-top: 20px;
}

table {
  width: 100%;
}

table,
th,
td {
  border: 1px solid black;
  padding: 8px;
}

.signing {
  display: flex;
  height: 100px;
  margin-top: 20px;
}

.sign-customer,
.sign-sale,
.sign-accountant {
  flex: 30%;
  text-align: center;
}

.sign-customer::after,
.sign-sale::after,
.sign-accountant::after {
  content: '(Ký, họ tên)';
  display: inherit;
  font-style: italic;
  font-size: 16px;
}
/* Ghi chú */
.note {
  margin-top: 20px;
}
/* Footer */
footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
}

footer p {
  margin-bottom: 0;
  color: #fff !important;
}
</style>
