<template>
  <div>
    <KTCodePreview v-bind:title="'In Hóa đơn'">
      <template v-slot:toolbar>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder"
          @click="onClickToPrint(printMode)"
        >
          <i
            style="font-size: 1rem"
            class="fas fa-print"
          ></i>In hóa đơn
        </b-button>
      </template>
      <template v-slot:preview>
        <hr
          class="hr-text"
          data-content="Khổ giấy A4- A5"
          style="font-weight: 600"
          v-show="printMode === 'A4'"
        />
        <div
          id="A4"
          v-if="renderComponent"
          v-show="printMode === 'A4'"
        >
          <PrintWarrantyBill :bill="bill" />
        </div>
        <br />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import PrintWarrantyBill from '../../components/bills/PrintWarrantyBill';
import { TIME_TRIGGER } from '@/utils/constants';
import saveData from '@/utils/saveDataToLocal';

export default {
  components: {
    KTCodePreview,
    PrintWarrantyBill,
  },
  data() {
    return {
      bill: {
        billNumber: '',
        storeId: '',
        storeName: '',
        billItems: [],
      },
      renderComponent: false,
      printMode: 'A4',
    };
  },
  async created() {
    let defaultPrintMode = saveData.getData('defaultPrintMode');
    if (!defaultPrintMode) {
      defaultPrintMode = 'A4';
      saveData.saveData('defaultPrintMode', this.printMode);
    }
    this.printMode = defaultPrintMode;
    const billNumber = this.$route.query.id;
    await this.getBillById(billNumber);
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn bảo hành', route: '/warranty-bill' },
      { title: 'In hóa đơn bảo hành' },
    ]);
  },
  methods: {
    getBillById: async function (billNumber) {
      this.renderComponent = false;
      await ApiService.get(`bills/${billNumber}`).then((response) => {
        const { data } = response.data;
        this.bill = data;
        this.renderComponent = true;
      });
    },
    printData(mode, stylePrint) {
      const prtHtml = document.getElementById(mode).innerHTML;
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );
      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
            ${stylePrint}
          </head>
          <body`);
      WinPrint.document.write(`${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
      }, TIME_TRIGGER);
    },
    getStylePrint() {
      return `<style>
                  .font-size-print {font-size: 20px !important; color: black;}
                  .font-size-label-print{font-size: 30px !important; color: black;}
                  .font-size-gift {font-size: 18px !important; color: black;}
                  .font-size-warranty{ font-size: 20px !important; color: black;}
                  .font-size-product-info{ font-size: 20px !important; color: black;}
                  .item-print {
                      width: 100% !important;
                      margin-left: 0px !important;
                    }
                  .heading-logo {
                    object-fit: contain;
                     width: 80%;
                  }
                  .font-size-sign {font-size: 15px}
                </style>`;
    },
    onClickToPrint(mode) {
      this.printMode = mode;
      const stylePrint = this.getStylePrint();
      this.printData(mode, stylePrint);
    },
  },
};
</script>