<template>
  <div class="app-credentials">
    <KTCodePreview v-bind:title="pageTitle">
      <template v-slot:toolbar>
        <b-row>
          <router-link
            :to="{
              name: 'add-app-credentials',
            }"
          >
            <b-button variant="primary" size="sm" class="font-weight-bolder">
              <i style="font-size: 1rem" class="flaticon2-add-1"></i> Tạo mới
            </b-button>
          </router-link>
        </b-row>
      </template>
      <template v-slot:preview>
        <span class="svg-icon svg-icon-lg mr-1 text-primary">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
        <strong>Ứng dụng</strong> uỷ quyền dành cho đối tác, cung cấp
        <code>clientId</code>
        và <code>clientSecet</code> thông qua api gateway được phân quyền dựa
        vào các quyền cơ bản trên app core
      </template>
    </KTCodePreview>

    <div v-for="(parentChunk, parentIndex) in dataTables" :key="parentIndex">
      <b-row class="mb-4">
        <b-col
          cols="4"
          v-for="(childChunk, childIndex) in parentChunk"
          :key="childIndex"
        >
          <b-card header-tag="header">
            <template #header>
              <div class="d-flex d-inline m-2">
                <h5 class="w-100 mb-0 mr-2">{{ childChunk.name }}</h5>
                <b-badge
                  class="w-50 justify-content-end"
                  :variant="childChunk.active ? 'success' : 'danger'"
                >
                  {{
                    childChunk.active ? 'Hoạt động' : 'Không hoạt động'
                  }}</b-badge
                >
              </div>
            </template>
            <b-card-text>
              <span class="mb-2 d-block">
                <strong>Đối tác</strong>:
                {{ childChunk.partnerName }}
              </span>
              <span class="mb-2 d-block">
                <strong>Cấu hình</strong>:
                {{
                  childChunk.appConfigName
                    ? childChunk.appConfigName
                    : 'Không có'
                }}
              </span>
              <span class="mb-2 d-block">
                <strong>Quyền</strong>:
                {{ childChunk.roleId ? childChunk.roleId : 'Chưa rán quyền' }}
              </span>
            </b-card-text>

            <a href="#" class="card-link" @click.prevent="editData(childChunk)">
              <i style="font-size: 1rem" class="flaticon2-pen"></i> Chỉnh sửa</a
            >
            <a
              href="#"
              class="card-link"
              @click.prevent="removeData(childChunk)"
            >
              <i
                style="font-size: 1rem; color: #d33"
                class="flaticon2-rubbish-bin-delete-button"
              ></i>
              Xoá</a
            >
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col class="d-flex d-inline">
        <div class="w-100 d-flex justify-content-start">
          <p class="mt-3 text-dark" style="font-weight: 500">
            Tổng số :
            {{ convertPrice(totalItem) }}
          </p>
        </div>
        <div v-show="numberOfPage" class="w-100 d-flex justify-content-end">
          <div class="overflow-auto">
            <b-pagination-nav
              pills
              use-router
              class="bg-light"
              style="border-radius: 5px"
              size="md"
              align="center"
              :number-of-pages="numberOfPage"
              :link-gen="linkGen"
              @change="fetchData"
            ></b-pagination-nav>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { convertPrice, makeToastSuccess, makeToastFaile } from '@/utils/common';
import { cloneDeep } from '../../../utils/common';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      dpConfigs: {},
      apiParams: {},
      pageTitle: '',
      dataTables: [],
      onLoading: false,
      numberOfPage: 1,
      totalItem: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      onBusy: false,
    };
  },
  components: {
    KTCodePreview,
  },
  computed: {},
  mounted() {
    this.pageTitle = 'Danh sách Credentials';
    this.$store.dispatch(SET_BREADCRUMB, [{ pageTitle: this.pageTitle }]);
  },
  created() {
    this.onInitData();
    this.fetchData();
  },
  methods: {
    convertPrice,
    onInitData() {},
    fetchData() {
      const PAGE_SIZE = 9;
      ApiService.get('/app-credentials', {
        size: PAGE_SIZE,
      }).then((response) => {
        const { data, total, total_page } = response.data.data;
        const dataChunks = this.seprateArrayDivide(data);
        this.totalItem = total;
        this.numberOfPage = total_page || 1;
        this.dataTables = dataChunks;
      });
    },
    seprateArrayDivide(data) {
      const perChunk = 3;
      const originalArray = cloneDeep(data);

      const chunkArray = originalArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
      return chunkArray;
    },
    editData(item) {
      this.$router.push({
        name: 'update-app-credentials',
        params: { id: item.id },
      });
    },
    removeData(item) {
      if (this.onBusy) {
        return;
      }
      Swal.fire({
        title: "<h1 class='text-danger'>Bạn có chắc muốn xoá</h1>",
        text: 'Xoá ứng dụng khônng thể hoàn tác!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Trở về',
      }).then((result) => {
        this.onBusy = true;
        if (result.value) {
          ApiService.delete(`app-credentials/${item.id}`)
            .then((result) => {
              makeToastSuccess(result.data.message);
              this.fetchData();
              this.onBusy = false;
            })
            .catch((error) => {
              this.onBusy = false;
              if (error.response) {
                makeToastFaile(
                  error.response.data ? error.response.data.message : 'Lỗi',
                );
              } else {
                makeToastFaile(error.message || 'Lỗi');
              }
            });
        }
        this.onBusy = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-credentials {
  .card-header {
    padding: 1rem 1.25rem;
  }
}
</style>
