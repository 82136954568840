<template>
  <b-modal
    hide-footer
    :title="title"
    ref="product-require-stock"
    id="product-require-stock"
  >
    <b-row class="mb-4">
      <b-col cols="12">
        <b-form-group label="Danh sách cần xuất:">
          <b-form-radio-group v-model="selectedListExcel">
            <b-form-radio value="filter">Theo bộ lọc</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-progress
      :max="100"
      v-if="isBusy"
    >
      <b-progress-bar
        :value="processBar"
        :label="`${processBar}% đang tải`"
      ></b-progress-bar>
    </b-progress>
    <b-row class="mt-5">
      <b-col>
        <b-button
          v-if="!isBusy"
          class="btn-width-25"
          variant="primary"
          size="sm"
          @click="exportData"
        ><i class="fa-solid fa-download"></i>Xuất dữ liệu</b-button>

        <b-button
          v-else
          class="btn-width-25"
          disabled
        >
          <b-spinner
            small
            type="grow"
          ></b-spinner>
          Đang tải...
        </b-button>
        <b-button
          class="btn-width-25 ml-2"
          variant="secondary"
          size="sm"
          @click="hideModal"
        >Hủy</b-button>
      </b-col>
    </b-row>
  </b-modal>

</template>

<script>
import ApiService from '@/core/services/api.service';
import fileDownload from '@/utils/file-download';
import { makeToastSuccess, makeToastFaile } from '@/utils/common';

export default {
  props: {
    apiParams: {
      type: Object,
    },
    title: {
      type: String,
    },
    baseUrl: {
      type: String,
    },
  },
  data() {
    return {
      processBar: 0,
      countProcessBar: 0,
      isBusy: false,
      selectedListExcel: 'filter',
    };
  },
  created() {},
  methods: {
    hideModal() {
      this.$refs['product-require-stock'].hide();
    },
    showModal() {
      this.processBar = 0;
      this.$refs['product-require-stock'].show();
    },
    async exportData() {
      try {
        if (this.isBusy) return;
        this.isBusy = true;
        this.processBar = 0;
        const response = await ApiService.query(this.baseUrl, {
          params: this.apiParams,
          responseType: 'blob',
          onDownloadProgress: function (progressEvent) {
            this.countProcessBar++;
            if (
              progressEvent['srcElement'].response &&
              progressEvent['srcElement'].response.size
            ) {
              this.processBar = 100;
            } else {
              if (this.processBar < 99) {
                this.processBar += 1;
              }
            }
          }.bind(this),
        });
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
        return makeToastSuccess('Tải xuống dữ liệu thành công');
      } catch (error) {
        const { message } = JSON.parse(await error.response.data.text());
        return makeToastFaile(message || 'Lỗi');
      } finally {
        this.isBusy = false;
        this.hideModal();
      }
    },
  },
};
</script>

<style scoped>
.btn-width-25 {
  width: 25%;
  font-weight: 600;
}
</style>