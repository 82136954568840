import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './core/services/store';
import '@/core/api';
import ApiService from './core/services/api.service';
import { VERIFY_AUTH } from './core/services/store/modules/auth.module';
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/modules/config.module';

import UUID from 'vue-uuid';
import VueAutosuggest from 'vue-autosuggest';
import VueMask from 'v-mask';
import NProgress from 'vue-nprogress';
import VueClipboard from 'vue-clipboard2';
import VueChartkick from 'vue-chartkick';
import Chart from 'chart.js';
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
import SweetAlertPlugin from './core/plugins/sweet-alert2';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
// Globally register all `_base`-prefixed components
import '@/view/base/_globals';

// Using leaflet map
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.config.productionTip = false;

Vue.use(UUID);
Vue.use(VueAutosuggest);
Vue.use(VueMask);
Vue.use(NProgress);
Vue.use(VueChartkick.use(Chart));
Vue.use(SweetAlertPlugin);

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;
const nprogress = new NProgress();
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// Vue 3rd party plugins
import i18n from './core/plugins/vue-i18n';
import vuetify from './core/plugins/vuetify';
import './core/plugins/portal-vue';
import './core/plugins/bootstrap-vue';
import './core/plugins/perfect-scrollbar';
import './core/plugins/highlight-js';
import './core/plugins/inline-svg';
import './core/plugins/apexcharts';
import './core/plugins/metronic';
import './core/plugins/datepicker';
import '@mdi/font/css/materialdesignicons.css';
// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

// Sentry.init({
//   Vue,
//   dsn: 'https://c7064f6606cd42968e7483bc13fe0629@sentry.ddverp.com/3',
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracePropagationTargets: ['app.didongviet.vn', 'app.ddverp.com', /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

Vue.config.errorHandler = function(err) {
  console.log('errorHandler', err);
  nprogress.done();
};

Vue.directive('focus', {
  inserted: function(el) {
    // Focus the element
    el.focus();
  },
  update: function(el, binding) {
    var value = binding.value;
    if (value) {
      Vue.nextTick(function() {
        el.focus();
      });
    }
  },
});

new Vue({
  router,
  store,
  i18n,
  nprogress,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
