<template>
  <div class="add-transfer-stock">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-row class="pl-0">
          <b-col cols="6">
            <b-form-group class="required-control">
              <label>Tên ngành hàng: </label>
              <b-form-input
                size="sm"
                v-model="mainModel.name"
                aria-describedby="input-fullName-live-feedback"
                placeholder="Nhập tên ngành hàng"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <label>Telegram group ID:</label>
              <b-form-input
                size="sm"
                v-model="mainModel.telegramGroupId"
                aria-describedby="input-fullName-live-feedback"
                placeholder="Nhập ID nhóm telegram..."
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <label style="font-weight: 500px">Danh sách nhân sự phụ trách:</label>
            <multiselect
              v-model="mainModel.purchasingDepartmentAssignments"
              placeholder="Tìm kiếm theo tên nhân sự"
              label="employeeName"
              track-by="employeeName"
              :options="listEmployee"
              :multiple="true"
              :taggable="true"
              :selectLabel="'Chọn nhân sự'"
              :deselectLabel="'Xoá nhân sự'"
            ></multiselect>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="6">
            <b-form-group>
              <label>Chính sách duyệt phiếu nhập hàng:</label>
              <b-form-textarea
                size="lg"
                rows="3"
                v-model="mainModel.storePoApprovalPolicy"
                aria-describedby="input-fullName-live-feedback"
                placeholder="Nhập chính sách duyệt phiếu..."
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template v-slot:foot>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          v-if="action && !isBusy && checkPermission('PURCHASING_DEPARTMENT_CREATE')"
          @click="onSubmitDebounce()"
        >
          {{ actionName }}
        </b-button>
        <b-button
          v-if="isBusy"
          variant="primary"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          disabled
        >
          <b-spinner
            small
            type="grow"
          ></b-spinner>
          {{ actionName }}...
        </b-button>
        <b-button
          variant="secondary"
          size="sm"
          class="btn-size font-weight-600"
          @click="goBack()"
        >Trở về</b-button>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { TIME_TRIGGER } from '@/utils/constants';
import { makeToastFaile } from '@/utils/common';
import { makeToastSuccess } from '@/utils/common';
import { STATUS_CODE } from '@/utils/enum';
import debounce from 'lodash/debounce';
import Multiselect from 'vue-multiselect';

export default {
  data() {
    return {
      listEmployee: [],
      actionName: 'Lưu',
      action: null,
      title: 'Thêm ngành hàng',
      mainModel: {
        id: null,
        name: null,
        purchasingDepartmentAssignments: [],
      },
      isBusy: false,
    };
  },
  components: {
    KTCodePreview,
    Multiselect,
  },
  async created() {
    const { id, action } = this.$route.query;
    this.action = action;
    const promises = [this.getEmployee()];
    if (id) {
      this.mainModel.id = id;
      promises.push(this.getPurchasingDepartmentInfo());
      this.title = 'Cập nhật cấu hình ngành hàng';
    }
    await Promise.all(promises);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getPurchasingDepartmentInfo() {
      try {
        const { data } =
          (await ApiService.get(
            `purchasing-department/${this.mainModel.id}`,
          )) || {};
        this.mainModel = data?.data;
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      }
    },
    async getEmployee() {
      const {
        data: { data },
      } = await ApiService.query('employees/getAll');
      this.listEmployee = data.map((item) => {
        return {
          employeeId: item.id,
          employeeName: item.fullName,
        };
      });
    },
    createPurchasingDepartment(body) {
      return ApiService.post('purchasing-department', body);
    },
    updatePurchasingDepartment(body) {
      return ApiService.patch(
        `purchasing-department/${this.mainModel.id}`,
        body,
      );
    },
    dispatchPurchasingDepartmentRequest(action, body) {
      switch (action) {
        case 'create':
          return this.createPurchasingDepartment(body);
        case 'update':
          return this.updatePurchasingDepartment(body);
        default:
          throw new Error(`Action: '${action}' không hợp lệ!`);
      }
    },
    onSubmitDebounce: debounce(function () {
      this.onSubmit();
    }, TIME_TRIGGER),
    async onSubmit() {
      if (this.isBusy) return;
      this.isBusy = true;
      try {
        const result = await this.dispatchPurchasingDepartmentRequest(
          this.action,
          this.mainModel,
        );
        if (result.data?.status === STATUS_CODE.SUCCESS) {
          makeToastSuccess(result.data.message);
          this.goBack();
        }
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      } finally {
        this.isBusy = false;
      }
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
<style scoped>
.font-weight-600 {
  font-weight: 600;
}

.btn-size {
  width: 90px;
}
</style>
