<template>
    <div class="container print-page">
        <table>
            <colgroup>
                <col style="width: 20%;">
                <col style="width: 30%;">
                <col style="width: 10%;">
                <col style="width: 30%;">
                <col style="width: 10%;">
            </colgroup>
            <tr>
      <td><img class="logo" src="/media/logos/vdd_hori_logo.png" alt="Logo"></td>
    <td colspan="4" class="header-title" >Phiếu Thông Tin Sản Phẩm</td>
    </tr>
            <tr>
                <td>Tên máy</td>
                <td>{{productName}}</td>
                <td>IMEI</td>
                <td colspan="2">{{imeiCode}}</td>
            </tr>
            <tr>
                <td>Mã Quốc Gia</td>
                <td></td>
                <td>Dung lượng</td>
                <td colspan="2"></td>
            </tr>
            <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
            <tr>
                <td>Mainboard</td>
                <td>Nguyên Gốc</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
                <td>Đã sửa chữa</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
            </tr>
            <tr>
                <td>Camera Trước</td>
                <td>Nguyên Gốc</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
                <td>Đã sửa chữa</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
            </tr>
            <tr>
                <td>Camera Sau</td>
                <td>Nguyên Gốc</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
                <td>Đã sửa chữa</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
            </tr>
            <tr>
                <td>Khung viền</td>
                <td>Nguyên Gốc</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
                <td>Đã sửa chữa</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
            </tr>
            <tr>
                <td>Loa Trong</td>
                <td>Nguyên Gốc</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
                <td>Đã sửa chữa</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
            </tr>
            <tr>
                <td>Loa Ngoài</td>
                <td>Nguyên Gốc</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
                <td>Đã sửa chữa</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
            </tr>
            <tr>
                <td colspan="5" style="text-align: center; padding-left: 8px;">Linh kiện hao mòn theo thời gian</td>
            </tr>
            <tr>
                <td>Pin</td>
                <td>Nguyên Gốc</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
                <td>Đã thay thế</td>
                <td><input type="checkbox" style="display: block; margin: 0 auto;" /></td>
            </tr>
            <tr>
                <td>Dung lượng pin còn lại</td>
                <td><span>...............</span></td>
                <td style="text-align: center;">%</td>
                <td></td>
                <td></td>
            </tr>
        </table>
    </div>
</template>

<script>


// import html2pdf from "html2pdf.js";

export default {
    name: "PrintImeiProduct",
    props: [
        'productName',
        'imeiCode',
    ],
    methods: {},
};
</script>

<style scoped>
.container {
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
}

.logo {
max-height: 45px;
}

.header-title {
  font-weight: bold;
  text-align: center;
  font-size: 30px; 
}

table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

table, th, td {
    border: 1px solid black;
    text-align: left;
    padding: 40px;
    font-size: 20px;
}

</style>