const Business = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "business" */ '@/view/pages/businesses/Business.vue'
  );
const BusinessList = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "business-list" */ '@/view/pages/businesses/BusinessList.vue'
  );
const BusinessUpsert = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "business-upsert" */ '@/view/pages/businesses/BusinessUpsert.vue'
  );

export default [
  {
    path: '/businesses',
    component: Business,
    children: [
      {
        path: '/',
        name: 'list-business',
        component: BusinessList,
        meta: {
          title: 'Danh sách khách hàng - VDD',
        },
      },
      {
        path: 'add',
        name: 'add-business',
        component: BusinessUpsert,
        meta: {
          title: 'Thêm mới khách hàng doanh nghiệp - VDD',
        },
      },
      {
        path: 'update/:id',
        name: 'update-business',
        component: BusinessUpsert,
        meta: {
          title: 'Cập nhật khách hàng doanh nghiệp - VDD',
        },
        props: true,
      },
    ],
  },
];
