<template>
  <tr class="heighRow">
    <td class style="padding: 0 5px 0 5px; width: 5%; text-align: center">
      <span v-if="productItem.billItemType === 1">{{ productItem.stt }}</span>
      <span v-else>
        <b-form-checkbox
          v-model="productItem.checked"
          size="lg"
          @change="checkvalidation"
          :disabled="true"
        ></b-form-checkbox>
      </span>
    </td>
    <td class style="padding: 0 5px 0 5px; width: 22%">
      <div class="mt-2 mb-2">
        <p
          style="font-weight: 500"
          class="m-0"
          v-show="productItem.code !== ''"
        >
          ({{ productItem.code }})
        </p>
        <p style="font-weight: 600" class="m-0">
          {{ productItem.name }}
        </p>
        <b-form-group v-show="productItem.type === 2">
          <b-form-input
            v-model="$v.productItem.imeiCode.$model"
            placeholder="Nhập IMEI code"
            class="form-control form-control-sm"
            type="text"
            aria-describedby="input-imei-live-feedback"
            @change="isAvailableQuantity(productItem.imeiCode)"
            :disabled="true"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="$v.productItem.imeiCode.minLength"
            id="input-imei-live-feedback"
            >Vui lòng nhập imei code ít nhất 5 kí tự</b-form-invalid-feedback
          >
          <b-form-invalid-feedback
            :state="validate"
            id="input-imei-live-feedback"
            >{{ messageError }}</b-form-invalid-feedback
          >
        </b-form-group>
        <span
          class="label font-weight-bold label-lg label-inline"
          v-bind:class="{
            'label-light-warning': productItem.merchantId === 1,
            'label-light-success': productItem.merchantId === 2,
          }"
          style="width: max-content"
          >{{ getMerchantName(productItem.merchantId) }}</span
        >
        <div
          class="table-responsive"
          v-show="
            productItem.comboItems !== undefined &&
              productItem.comboItems.length > 0
          "
        >
          <table>
            <tr
              v-for="(items, i) in productItem.comboItems"
              :key="i"
              style="border-top: 1px solid gray"
            >
              <td style="border: none">
                <i style="color: rgb(24, 28, 50)" class="fas fa-cube"></i>
              </td>
              <td style="border: none; width: -webkit-fill-available">
                {{ items.productName }}
                <i style="font-size: unset; color: unset"
                  >({{ formatMoney(items.sellingPrice) }})</i
                >
              </td>
              <td style="border: none">{{ items.quantity }}</td>
            </tr>
          </table>
        </div>
      </div>
    </td>
    <td class style="padding: 0 5px 0 5px; width: 8%; text-align: right">
      <div v-if="productItem.customerDiscountAmount" style="visibility: hidden">
        hidden
      </div>
      <div
        v-if="
          (productItem.type === 1 ||
            productItem.type === 3 ||
            productItem.type === 4) &&
            productItem.billItemType === 1
        "
      >
        <b-form-input
          v-if="editable && productItem.billItemType === 1"
          style="
            height: calc(1.35rem + 1.1rem + 2px);
            border-color: #007bff;
            text-align: right;
          "
          v-model="productItem.quantity"
          type="number"
          placeholder="Số lượng"
          class="w-100 h-90"
          @change="checkvalidation"
        ></b-form-input>
        <span v-else>{{ productItem.quantity }}</span>
      </div>

      <span v-else>{{ productItem.quantity }}</span>
    </td>
    <td class style="padding: 0 5px 0 5px; width: 15%; text-align: right">
      
      <div v-if="productItem.customerDiscountAmount" style="visibility: hidden">
        hidden
      </div>
      <span>{{ formatMoney(productItem.price) }}</span>
    </td>
    <td class style="padding: 0 5px 0 5px; width: 8%; text-align: right">
      <div v-if="productItem.customerDiscountAmount" style="visibility: hidden">
        hidden
      </div>
      <span v-if="productItem.billItemType === 1">{{
        formatMoney(productItem.allotmentDiscountAmount)
      }}</span>
    </td>
    <td class style="padding: 0 5px 0 5px; width: 12%; text-align: right">
      <div v-if="productItem.customerDiscountAmount" style="visibility: hidden">
        hidden
      </div>
      <span v-if="productItem.billItemType === 1">{{
        formatMoney(totalPriceOfProduct)
      }}</span>
      <div v-else class="mb-2">
        <div
          v-if="
            productItem.billItemType === 2 || productItem.billItemType === 3
          "
        >
          <span class="text-primary">{{
            productItem.billItemType === 2 ? 'Giá thu lại' : 'Giá chiết khấu'
          }}</span>
          <br />
          <b-form-input
            v-if="editable"
            size="sm"
            style="
              height: calc(1.35rem + 1.1rem + 2px);
              border-color: #007bff;
              text-align: right;
            "
            v-model="productItem.returnPrice"
            :placeholder="
              productItem.billItemType === 2 ? 'Giá thu lại' : 'Giá chiết khấu'
            "
            type="text"
            :min="1"
            class="w-100 h-90"
            @change="checkvalidation"
            v-mask="mask"
          ></b-form-input>
          <span v-else>{{ formatMoney(productItem.returnPrice) }}</span>
        </div>
      </div>
    </td>
    <td class style="padding: 0 5px 0 5px; width: 17%; text-align: right">
      <div>
        <span
          v-if="
            productItem.customerDiscountType === 'percent' &&
              productItem.customerDiscountAmount &&
              productItem.billItemType !== ITEM_TYPE.GIFT
          "
          class="font-italic"
          style="color: rgb(255, 168, 0)"
        >
          Thành viên: {{ productItem.customerDiscountAmount }}%</span
        >
        <span
          v-if="
            productItem.customerDiscountType === 'amount' &&
              productItem.customerDiscountAmount &&
              productItem.billItemType !== ITEM_TYPE.GIFT
          "
          class="font-italic"
          style="color: rgb(255, 168, 0)"
        >
          Thành viên:
          {{ formatPrice(productItem.customerDiscountAmount) }} VND</span
        >
      </div>
      <div v-if="editable && productItem.billItemType === 1">
        <b-input-group>
          <template #prepend>
            <b-dropdown
              :text="
                productItem.discountType == DISCOUNT_AMOUNT_TYPE.MONEY
                  ? '$'
                  : productItem.discountType == DISCOUNT_AMOUNT_TYPE.PERCENT
                  ? '%'
                  : ''
              "
              size="sm"
              @change="checkvalidation"
              variant="outline-primary"
            >
              <b-dropdown-item @click="onClickDiscountType(1)">{{
                '$'
              }}</b-dropdown-item>
              <b-dropdown-item @click="onClickDiscountType(2)">{{
                '%'
              }}</b-dropdown-item>
            </b-dropdown>
          </template>
          <b-form-input
            size="sm"
            style="
              height: calc(1.35rem + 1.1rem + 2px);
              border-color: #007bff;
              text-align: right;
            "
            v-model="productItem.discount"
            placeholder="Chiết khấu"
            type="text"
            :min="1"
            class="w-90 h-90"
            @change="checkvalidation"
            v-mask="mask"
          ></b-form-input>
        </b-input-group>
      </div>
      <span v-else
        >{{ formatMoney(productItem.discount) }}{{ discountTypeText }}</span
      >
    </td>

    <td style="width: 5%" v-if="editable">
      <template>
        <div class="d-flex justify-content-center">
          <v-icon
            small
            class="text-danger"
            @click="showDeleteAlert"
            v-b-tooltip
            title="Xóa"
            >mdi-delete</v-icon
          >
        </div>
      </template>
    </td>
  </tr>
</template>

<style>
.heighRow {
  height: 40px;
}
*:focus {
  outline: none;
}
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>

<script>
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { minLength } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import { currencyMask, unMaskPrice } from './../../utils/common';
import { AMOUNT_TYPE, ITEM_TYPE } from '../../utils/enum';

export default {
  props: ['productItem', 'editable'],
  mixins: [validationMixin],
  data() {
    return {
      error: false,
      productDetail: null,
      validate: true,
      mask: currencyMask,
      messageError: '',
      DISCOUNT_AMOUNT_TYPE: AMOUNT_TYPE,
      ITEM_TYPE: ITEM_TYPE,
    };
  },
  computed: {
    totalPriceOfProduct() {
      const discountType = this.productItem.discountType;

      if (this.productItem.quantity <= 0) {
        return 0;
      }
      let totalAmount = 0;

      // total giá chiết khấu

      totalAmount =
        unMaskPrice(this.productItem.quantity) *
        unMaskPrice(this.productItem.price);

      if (discountType == AMOUNT_TYPE.PERCENT) {
        let amount = totalAmount * unMaskPrice(this.productItem.discount);
        amount = (amount / 100).toFixed(0);
        totalAmount = totalAmount - amount > 0 ? totalAmount - amount : 0;
      }
      if (discountType == AMOUNT_TYPE.MONEY) {
        totalAmount =
          totalAmount - unMaskPrice(this.productItem.discount) > 0
            ? totalAmount - unMaskPrice(this.productItem.discount)
            : 0;
      }
      if (this.productItem.customerDiscountType === 'percent') {
        let amount =
          totalAmount * (this.productItem.customerDiscountAmount / 100);

        if (
          this.productItem.customerDiscountMaxAmount &&
          amount > this.productItem.customerDiscountMaxAmount
        ) {
          amount = this.productItem.customerDiscountMaxAmount;
        }

        // if(amount > this.productItem.customer)
        totalAmount = totalAmount - amount.toFixed(0);

        totalAmount = Number(totalAmount) < 0 ? 0 : totalAmount;
      }
      if (this.productItem.customerDiscountType === 'amount') {
        totalAmount =
          totalAmount - this.productItem.customerDiscountAmount < 0
            ? 0
            : totalAmount - this.productItem.customerDiscountAmount;
      }
      const finalTotalAmount =
        totalAmount - this.productItem.allotmentDiscountAmount;
      return finalTotalAmount > 0 ? finalTotalAmount : 0;
    },
    // totalPriceOfProduct() {
    //   const discountType = this.productItem.discountType;
    //   const totalAmount =
    //     this.productItem.quantity * unMaskPrice(this.productItem.price);
    //   if (discountType === AMOUNT_TYPE.PERCENT) {
    //     let amount = totalAmount * unMaskPrice(this.productItem.discount);
    //     amount = (amount / 100).toFixed(0);
    //     return totalAmount - amount - this.productItem.allotmentDiscountAmount;
    //   }
    //   return (
    //     totalAmount -
    //     unMaskPrice(this.productItem.discount) -
    //     this.productItem.allotmentDiscountAmount
    //   );
    // },
    discountTypeText() {
      return this.productItem.discountType == AMOUNT_TYPE.PERCENT ? '%' : '';
    },
  },
  created() {},
  validations: {
    productItem: {
      imeiCode: {
        minLength: minLength(5),
      },
    },
  },
  updated() {},
  methods: {
    isAvailableQuantity: async function(value) {
      this.validate = false;
      let dataResponse = {
        id: this.productItem.id,
        productId: this.productItem.productId,
        imeiCode: this.productItem.imeiCode,
        name: this.productItem.name,
        quantity: this.productItem.quantity,
        price: this.productItem.price,
        totalPrice: this.totalPriceOfProduct,
        discount: this.productItem.discount,
      };

      if (value === '') return this.validate;

      let params = {
        storeId: this.productItem.storeId,
        imei: this.productItem.imeiCode,
      };
      await ApiService.setHeader();
      await ApiService.query('bills/check-valid-imei', {
        params,
      })
        .then((response) => {
          if (response.data.status === 1) {
            this.validate = true;
          } else {
            this.validate = false;
            this.messageError = response.data.message;
          }
          this.$emit('checkValidImei', this.validate);
          this.$emit('update', dataResponse);
        })
        .catch(() => {
          this.$emit('checkValidImei', this.validate);
          this.$emit('update', dataResponse);
          this.validate = false;
        });

      return false;
    },
    checkvalidation() {
      this.error = false;
      let dataResponse = this.productItem;
      dataResponse.price = unMaskPrice(this.productItem.price);
      dataResponse.totalPrice = unMaskPrice(this.productItem.totalPrice);
      this.$emit('update', dataResponse);
    },
    showDeleteAlert: function() {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này khỏi hóa đơn không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.productItem.id);
        }
      });
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    onClickDiscountType(type) {
      if (type === AMOUNT_TYPE.MONEY) {
        this.productItem.discountType = AMOUNT_TYPE.MONEY;
      } else if (type === AMOUNT_TYPE.PERCENT) {
        this.productItem.discountType = AMOUNT_TYPE.PERCENT;
      }
      this.checkvalidation();
    },
    getMerchantName(merchantId) {
      switch (merchantId) {
        case 1:
          return 'VDD';
      }
    },
  },
};
</script>
