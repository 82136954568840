<template>
  <div class="add-transfer-stock">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-row class="pb-5">
          <b-col cols="3">
            <label class="font-weight-bolder">Mã nhân sự </label>
            <b-form-input
              placeholder="Mã nhân sự"
              size="sm"
              autocomplete="off"
              v-model="mainModel.employeeCode"
              :disabled="true"
            />
          </b-col>
          <b-col cols="3">
            <label class="font-weight-bolder">Tên nhân sự </label>
            <b-form-input
              placeholder="Tên nhân sự"
              size="sm"
              autocomplete="off"
              v-model="mainModel.fullname"
              :disabled="true"
            />
          </b-col>
          <b-col cols="3">
            <label class="font-weight-bolder">Chức vụ</label>
            <b-form-input
              placeholder="Chức vụ"
              size="sm"
              autocomplete="off"
              v-model="mainModel.jobTitleName"
              :disabled="true"
            />
          </b-col>
          <b-col cols="3">
            <label class="font-weight-bolder">Cửa hàng</label>
            <b-form-input
              placeholder="Cửa hàng"
              size="sm"
              autocomplete="off"
              v-model="mainModel.storeName"
              :disabled="true"
            />
          </b-col>
        </b-row>
        <b-row class="pb-5">
          <b-col cols="3">
            <label class="font-weight-bolder">Tài khoản đăng nhập</label>
            <b-form-input
              placeholder="Tài khoản đăng nhập"
              size="sm"
              autocomplete="off"
              v-model="mainModel.username"
              :disabled="true"
            />
          </b-col>
          <b-col cols="3">
            <label class="font-weight-bolder">Số lần đăng nhập thất bại</label>
            <b-form-input
              placeholder="0"
              size="sm"
              autocomplete="off"
              v-model="mainModel.failedLoginCount"
              :disabled="true"
            />
          </b-col>
          <b-col cols="3">
            <label class="font-weight-bolder">Đăng nhập gần nhất</label>
            <b-form-input
              size="sm"
              autocomplete="off"
              v-model="mainModel.lastLoginDate"
              :disabled="true"
            />
          </b-col>
          <b-col cols="3">
            <label class="font-weight-bolder">Ngày thay đổi mật khẩu</label>
            <b-form-input
              size="sm"
              autocomplete="off"
              v-model="mainModel.passwordModifyDate"
              :disabled="true"
            />
          </b-col>
        </b-row>
        <hr class="hr-text font-weight-600" data-content="Lịch sử đăng nhập" />
        <b-row>
          <b-col></b-col>
          <b-col>
            <b-pagination
              pills
              v-if="shouldShowPagination"
              v-model="currentPage"
              :total-rows="totalUserTokensComputed"
              :per-page="perPage"
              aria-controls="user-token-table"
              align="right"
            ></b-pagination
          ></b-col>
        </b-row>
        <b-row>
          <b-table
            id="user-token-table"
            :items="mainModel.userTokenMetaData"
            :fields="fields"
            bordered
            hover
            :busy="onLoading"
            :per-page="perPage"
            :current-page="currentPage"
            show-empty
          >
            <template v-slot:table-busy>
              <vcl-table
                :speed="5"
                :animate="true"
                :columns="perPage"
              ></vcl-table>
            </template>
            <template #empty="">
              <h3 class="text-center">Không có dữ liệu!</h3>
            </template>
            <template v-slot:cell(index)="row">
              {{ row.index + 1 + perPage * (currentPage - 1) }}
            </template>
            <template v-slot:cell(expiredAt)="row">
              {{ formatDate(row.item.expiredAt, CLIENT_DATE_TIME) }}
            </template>
            <template v-slot:cell(createdAt)="row">
              {{ formatDate(row.item.createdAt, CLIENT_DATE_TIME) }}
            </template>
            <template v-slot:cell(revokedAt)="row">
              {{
                row.item.revokedAt &&
                formatDate(row.item.revokedAt, CLIENT_DATE_TIME)
              }}
            </template>
            <template v-slot:cell(deleted)="row">
              <span
                :class="getBadgeType(row.item).className"
                v-b-tooltip.hover
                :title="getUserTokenDeleteMessage(row.item)"
                >{{ getBadgeType(row.item).text }}</span
              >
            </template>

            <template v-slot:cell(actions)="row">
              <div class="d-flex justify-content-center">
                <b-dropdown
                  size="sm"
                  id="dropdown-left"
                  no-caret
                  right
                  v-show="!row.item.deleted"
                >
                  <template slot="button-content">
                    <i class="flaticon2-settings"></i>
                  </template>
                  <b-dropdown-item
                    v-if="checkPermission('USER_TOKEN_REVOKE')"
                    @click="
                      showDeleteAlert(
                        modalDeleteTitle,
                        modalDeleteDesc,
                        row.item,
                        confirmRevokeTokenById,
                      )
                    "
                  >
                    <span style="color: #3f4254; font-size: 10px">
                      <i class="fa-solid fa-clock-rotate-left text-danger"></i>
                      &nbsp; Thu hồi token
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
        </b-row>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark font-weight-bold">
              Tổng:
              {{ totalUserTokensComputed }}
            </p>
          </b-col>
          <b-col>
            <b-pagination
              pills
              v-if="shouldShowPagination"
              v-model="currentPage"
              :total-rows="totalUserTokensComputed"
              :per-page="perPage"
              aria-controls="user-token-table"
              align="right"
            ></b-pagination>
          </b-col>
        </b-row>
      </template>
      <template v-slot:foot>
        <b-button
          variant="secondary"
          size="sm"
          class="btn-size font-weight-600"
          @click="goBack()"
          >Trở về</b-button
        >
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { makeToastFaile } from '@/utils/common';
import { makeToastSuccess } from '@/utils/common';
import { cmdUrl } from '@/utils/apiUrl';
import {
  formatDate,
  getCurrentDate,
  CLIENT_DATE_TIME,
  formatSpecificDate,
  checkIsAfterTime,
  DATETIME_DB_FORMAT,
} from '@/utils/date';
import { orderBy } from 'lodash';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import { DELETE_TYPE } from '@/utils/enum';
import { USER_TOKEN_DELETED_BY_ACTION_NAME } from '@/utils/enum-name';
import { USER_TOKEN_DELETED_BY_ACTION } from '../../../utils/enum';

export default {
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      action: null,
      title: 'Chi tiết user token',
      mainModel: {
        id: null,
        username: '',
        fullname: 'null',
        failedLoginCount: '',
        passwordModifyDate: null,
        userTokenMetaData: [],
      },
      isBusy: false,
      fields: [
        {
          key: 'index',
          label: 'STT',
          sortable: true,
          thStyle: { fontWeight: 600, color: '#181c32', width: '2%' },
          tdClass: 'text-center',
        },
        {
          key: 'ip',
          label: 'IP',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '4%',
            textAlign: 'center',
          },
          tdClass: 'text-left',
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: true,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'expiredAt',
          label: 'Ngày hết hạn',
          sortable: true,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'revokedAt',
          label: 'Ngày thu hồi',
          sortable: true,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-left',
        },
        {
          key: 'userAgent',
          label: 'User Agent',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'deleted',
          label: 'Trạng thái',
          sortable: true,
          thStyle: { fontWeight: 600, color: '#181c32', width: '6%' },
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: { width: '5%' },
        },
      ],
      CLIENT_DATE_TIME: CLIENT_DATE_TIME,
      onLoading: false,
      modalDeleteTitle: 'Thu hồi token này',
      modalDeleteDesc:
        'Bạn có chắc muốn thu hồi token này. Thiết bị đăng nhập bằng token này sẽ bị đăng xuất!',
      shouldShowPagination: false,
    };
  },
  components: {
    KTCodePreview,
  },
  computed: {
    totalUserTokensComputed() {
      return this.mainModel.userTokenMetaData.length;
    },
  },
  created() {
    const { userId } = this.$route.query;
    if (userId) {
      this.mainModel.userId = userId;
      this.getUserTokenInfo();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  methods: {
    formatDate,
    showDeleteAlert,
    getUserTokenDeleteMessage(item) {
      if (item.deleted) {
        const deletedByActionName =
          USER_TOKEN_DELETED_BY_ACTION_NAME[item.deletedByAction];
        return deletedByActionName
          ? 'Tự động xoá khi: ' + deletedByActionName
          : 'Được xoá tự động';
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    getBadgeType(item) {
      const badeSetting = {
        text: 'Hoạt động',
        className: 'badge badge-success',
      };
      if (item.deleted) {
        badeSetting.text = 'Đã xoá';
        badeSetting.className = 'badge badge-danger';
      } else {
        const currentTime = getCurrentDate(DATETIME_DB_FORMAT);
        const expiredTime = formatSpecificDate(
          item.expiredAt,
          DATETIME_DB_FORMAT,
          DATETIME_DB_FORMAT,
        );
        if (checkIsAfterTime(currentTime, expiredTime)) {
          badeSetting.text = 'Đã hết hạn';
          badeSetting.className = 'badge badge-warning';
        }
      }
      return badeSetting;
    },
    async getUserTokenInfo() {
      try {
        const endPoint = cmdUrl.userToken.byId.replace(
          '{userId}',
          this.mainModel.userId,
        );
        const { data } = await ApiService.get(endPoint);
        this.mainModel = data?.data || {};
        this.mainModel.passwordModifyDate = this.mainModel.passwordModifyDate
          ? formatDate(this.mainModel.passwordModifyDate, CLIENT_DATE_TIME)
          : null;

        this.mainModel.lastLoginDate = this.mainModel.lastLoginDate
          ? formatDate(this.mainModel.lastLoginDate, CLIENT_DATE_TIME)
          : null;

        this.mainModel.userTokenMetaData = orderBy(
          this.mainModel.userTokenMetaData,
          ['deleted', 'createdAt'],
          ['asc', 'desc'],
        );

        this.shouldShowPagination =
          this.totalUserTokensComputed / this.perPage > 1;
      } catch (error) {
        this.makeToastErrorMessage(error);
      }
    },
    makeToastErrorMessage(error) {
      const message =
        error.response?.data?.message ?? error.message ?? 'Network error';
      makeToastFaile(message);
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    confirmRevokeTokenById(item) {
      const endPoint = cmdUrl.userToken.revokeToken.replace('{id}', item.id);
      ApiService.put(endPoint)
        .then(({ data }) => {
          makeToastSuccess(data?.message);
          const foundIndex = this.mainModel.userTokenMetaData.findIndex(
            ({ id }) => id === item.id,
          );
          this.mainModel.userTokenMetaData[foundIndex].deleted =
            DELETE_TYPE.DELETED;

          const currentDate = getCurrentDate(CLIENT_DATE_TIME);
          this.mainModel.userTokenMetaData[foundIndex].revokedAt =
            formatSpecificDate(currentDate, CLIENT_DATE_TIME);
          this.mainModel.userTokenMetaData[foundIndex].deletedByAction =
            USER_TOKEN_DELETED_BY_ACTION.REVOKE_TOKEN;
        })
        .catch((error) => this.makeToastErrorMessage(error));
    },
  },
};
</script>
<style scoped>
.font-weight-600 {
  font-weight: 600;
}

.btn-size {
  width: 90px;
}
.textarea-style {
  border: none;
}
</style>
