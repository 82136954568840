<template>
  <div class="mbf-transaction">
    <KTCodePreview v-bind:title="'Danh sách hoá đơn Mobifone'">
      <template v-slot:toolbar>
        <b-row>
          <b-dropdown size="sm" right class="px-0">
            <template slot="button-content">
              <i class="fas fa-cog" style="font-size: 1rem"></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="showModal">
              <span>
                <v-icon small class="text-warning mb-1" v-b-tooltip
                  >mdi-file-document</v-icon
                >
                Biên Bản Bàn Giao</span
              >
            </b-dropdown-item>
          </b-dropdown>
        </b-row>
      </template>
      <template v-slot:preview>
        <div class="mb-5">
          <b-row class="mb-5">
            <b-col cols="3">
              <b-form-select
                size="sm"
                v-model="selectedStore"
                :options="listStore"
                value-field="id"
                text-field="customAddress"
                class="mr-5 select-style"
                @change="fetchData()"
                :disabled="onBusy"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >-- Chọn cửa hàng --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="6" class="pl-0">
              <b-button
                size="sm"
                variant="primary"
                class="mr-3"
                v-show="(selectedStore ? true : false) !== onBusy"
                @click="fetchData()"
                title="Làm mới"
                :disabled="onBusy"
              >
                <v-icon small class="text-white" v-b-tooltip
                  >mdi-refresh</v-icon
                >
                <strong class="d-none d-md-inline-block">Làm Mới</strong>
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                disabled
                v-show="onBusy"
                class="mr-3"
              >
                <b-spinner small class="mr-2"> </b-spinner>
                <strong class="d-none d-md-inline-block">Đang Tải</strong>
              </b-button>
              <b-button
                size="sm"
                variant="warning"
                class="mr-3"
                v-show="showPushButton()"
                @click="pushToMBF"
                :disabled="onBusy"
              >
                <v-icon small class="text-white" v-b-tooltip>mdi-upload</v-icon>
                <strong class="d-none d-md-inline-block">Đẩy Lệnh</strong>
              </b-button>
            </b-col>
          </b-row>
          <div class="mb-2 d-flex bd-highlight">
            <div class="mr-auto bd-highlight">
              <b class="mr-2">Ngày: {{ currentDateDisplay }}</b>
            </div>
            <div class="bd-highlight">
              <b class="mr-2"
                >Tổng số lệnh:
                <span class="text-primary">{{ items.length || 0 }}</span></b
              >
              <b class="mr-2"
                >Đã đẩy:
                <span class="text-warning">{{
                  items.filter((x) => x.push).length || 0
                }}</span></b
              >
              <b
                >Chưa đẩy:
                <span class="text-danger">{{
                  items.filter((x) => !x.push).length || 0
                }}</span></b
              >
            </div>
          </div>
          <table
            class="table table-bordered table-vertical-center table-hover table-font-size table-sale table-responsive"
          >
            <thead>
              <tr>
                <th scope="col" class="checkbox-col">
                  <div class="d-flex justify-content-center">
                    <input
                      type="checkbox"
                      v-model="cbSelectAll"
                      @click="select"
                    />
                  </div>
                </th>
                <th scope="col" class="date-col">Ngày</th>
                <th scope="col">ID</th>
                <th scope="col" class="customer-col">Khách hàng</th>
                <th scope="col" class="product-col">Sản phẩm Vat</th>
                <th scope="col" class="quantity-col">SL</th>
                <th scope="col" class="quantity-col">Thuế suất</th>
                <th scope="col">Giá tiền</th>
                <th scope="col">C.khấu</th>
                <th scope="col">Tổng tiền</th>
                <th scope="col">C.thức</th>
                <th scope="col" class="check-col">KT lệnh</th>
                <th scope="col" class="check-col">Duyệt SP</th>
                <th scope="col"></th>
              </tr>
            </thead>

            <tbody v-if="items.length > 0">
              <tr v-for="(item, index) in items" :key="index">
                <td>
                  <div v-if="checkBillValid(item.idBill)" class="text-center">
                    <i
                      class="fas fa-times-circle text-danger icon-size"
                      v-b-tooltip.hover.bottom
                      title="Đơn lỗi"
                    ></i>
                  </div>

                  <div v-else class="text-center">
                    <div v-if="checkBillApprove(item.idBill)">
                      <small class="text-warning"
                        ><strong>Duyệt lệnh</strong></small
                      >
                    </div>
                    <div v-else>
                      <div v-if="pushedBills.includes(item.idBill)">
                        <i
                          class="fab fa-telegram text-primary icon-size"
                          v-b-tooltip.hover.bottom
                          title="Đã đẩy sang MBF"
                        ></i>
                      </div>
                      <div v-else>
                        <input
                          type="checkbox"
                          :value="item.idBill"
                          v-model="cbSelected"
                          @click="selectOne($event)"
                          :disabled="pushedBills.includes(item.idBill)"
                          style="border: 1px solid orange"
                        />
                        <br />
                        <i
                          class="fab fa-telegram text-danger icon-size"
                          v-b-tooltip.hover.bottom
                          title="Chưa đẩy"
                        ></i>
                      </div>
                    </div>
                  </div>
                </td>
                <td v-bind:title="formatDate(item.createdDateTime)">
                  {{ formatDate(item.createdDateTime) }}
                </td>
                <td v-bind:title="item.idBill">
                  <span class="text-primary"> {{ item.idBill }}</span>
                  <p v-bind:title="stripHtmlText(formatStore(item.shopCode))">
                    <strong v-html="formatStore(item.shopCode)"> </strong>
                  </p>
                </td>
                <td
                  v-bind:title="
                    stripHtmlText(
                      formatCustomer(item.customerName, item.customerMobile),
                    )
                  "
                  v-html="
                    formatCustomer(item.customerName, item.customerMobile)
                  "
                ></td>
                <td
                  v-bind:title="
                    stripHtmlText(
                      formatProductName(item.productName, item.barCode),
                    )
                  "
                  v-html="
                    formatProductName(item.productName, item.barCode, true)
                  "
                ></td>

                <td class="text-center">1</td>
                <td class="text-center">{{ item.vat }}</td>
                <td
                  v-bind:title="formatPrice(item.productPrice)"
                  class="text-right"
                >
                  {{ formatPrice(item.productPrice) }}
                  <small class="d-block text-danger">{{
                    formatPrice(item.sisAmount)
                  }}</small>
                </td>
                <td
                  v-bind:title="formatPrice(item.discount)"
                  class="text-right"
                >
                  {{ formatPrice(item.discount) }}
                </td>
                <td class="text-warning text-right">
                  <strong class="d-block">{{ formatPrice(item.money) }}</strong>
                </td>
                <td class="text-center">
                  <b-badge v-if="item.newCalculate" variant="warning"
                    ><span class="text-white font-weight-bolder"
                      >Mới</span
                    ></b-badge
                  >
                  <b-badge v-else variant="light"
                    ><span class="font-weight-bolder">Cũ</span></b-badge
                  >
                </td>
                <td
                  v-html="setTextStatus(item.status)"
                  class="text-center"
                ></td>
                <td class="text-center">
                  <b v-if="item.approve" class="text-success">Đã duyệt</b>
                  <b v-else class="text-warning">Chưa duyệt</b>
                </td>

                <td>
                  <!-- <b-dropdown size="sm" no-caret right lazy>
                    <template v-slot:button-content>
                      <i
                        style="font-size: 1rem"
                        class="flaticon2-settings pr-0"
                      ></i>
                    </template>
                    <b-dropdown-item
                      href="#"
                      v-on:click="updateBill(item.idBill, item.productId)"
                      title="Nhờ IT set tên trước khi cập nhật!!"
                    >
                      <v-icon class="text-success mr-1" v-b-tooltip small
                        >mdi-reload</v-icon
                      >
                      Cập nhật</b-dropdown-item
                    >
                    <b-dropdown-item href="#">
                          <v-icon class="text-danger" v-b-tooltip
                            >mdi-delete</v-icon
                          >Huỷ đẩy</b-dropdown-item
                        >
                  </b-dropdown> -->
                </td>
              </tr>
            </tbody>
          </table>
          <vcl-table
            v-if="onLoading"
            :speed="4"
            :animate="true"
            :columns="7"
          ></vcl-table>
        </div>
      </template>
    </KTCodePreview>
    <b-modal ref="my-modal" hide-footer title="Biên bản bàn giao">
      <v-form ref="form" lazy-validation>
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <label for="example-input">Chọn cửa hàng:</label>
              <b-form-select
                v-model="selectedStore"
                :options="listStore"
                required
                value-field="id"
                text-field="customAddress"
                :disabled="onBusy"
                v-on:input="getStateStoreModal($event)"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled
                    >-- Chọn cửa hàng --</b-form-select-option
                  >
                </template>
              </b-form-select>
            </div>
            <div class="col-6">
              <label for="example-input">Chọn ngày:</label>
              <b-input-group class="mb-3">
                <b-form-input
                  id="example-input"
                  v-model="datePicked"
                  type="text"
                  placeholder="Chọn ngày"
                  autocomplete="off"
                  :disabled="true"
                  @click="showDatePicker"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    ref="my-date-picker"
                    v-model="datePicked"
                    button-only
                    right
                    locale="vi"
                    aria-controls="example-input"
                    :max="max"
                    :disabled="onBusy"
                    v-on:input="getStateStoreModal($event)"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
        </div>

        <div v-show="selectedStore == null ? false : true">
          <b-form-group label="Trạng thái:">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selected"
              name="flavour-2"
            >
              <b-form-checkbox value="1">Đã ký bàn giao</b-form-checkbox>
              <b-form-checkbox value="2">Đã ký gửi kế toán</b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
          <b-form-group
            class="mb-4"
            label="Ghi Chú:"
            label-for="textarea-formatter"
          >
            <b-form-textarea
              id="textarea-formatter"
              placeholder="Nhập ghi chú"
              v-model="description"
              rows="3"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <b-button
          style="width: 150px"
          variant="success"
          size="sm"
          v-on:click="changeStatus()"
          class="mr-3"
          v-show="selectedStore == null ? false : true"
        >
          <strong>Cập Nhật Trạng Thái</strong>
        </b-button>
        <b-button
          style="width: 110px"
          variant="primary"
          size="sm"
          @click="reportClick"
          :disabled="onBusy"
          class="mr-3"
        >
          <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="hideModal"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';
// import stripHtml from 'string-strip-html';
import { VclTable } from 'vue-content-loading';
import { BASE_URL } from './../../../utils/constants';
import { makeToastFaile, makeToastSuccess } from '../../../utils/common';
export default {
  data() {
    return {
      search: '',
      items: [],
      selectedStore: null,
      listStore: [],
      selectedAll: false,
      onBusy: false,
      isNoData: false,
      file: '',
      fileName: '',
      Toast: '',
      datePicked: '',
      max: moment().format('YYYY-MM-DD'),
      selected: [],
      description: '',
      cbSelected: [],
      cbSelectAll: false,
      pushedBills: [],
      onLoading: false,
    };
  },
  components: {
    KTCodePreview,
    VclTable,
  },
  computed: {
    currentDateDisplay() {
      return moment().format('YYYY-MM-DD');
    },
  },
  created() {
    this.getListStore();
    this.Toast = this.showMixinSweetAlert();
    this.fetchStoreByUser();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Danh sách đơn hàng' }]);
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    showMixinSweetAlert() {
      return Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
    },
    async getFileTmp() {
      let url = process.env.VUE_APP_TMP_FILE_BILL;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let fileName = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex,
        );
        fileLink.setAttribute('download', fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    getListStore: async function () {
      this.listStore = [];
      ApiService.get('mbf/stores').then((response) => {
        this.listStore = response.data.data || [];
      });
    },
    fetchData: function () {
      if (this.selectedStore) {
        this.cbSelected = [];
        this.cbSelectAll = false;
        this.items = [];
        this.pushedBills = [];
        this.onBusy = true;
        this.onLoading = true;

        const url =
          'mbf/transactions' +
          '?storeId=' +
          this.selectedStore +
          '&date=' +
          moment().format('YYYY-MM-DD');

        ApiService.get(url)
          .then((response) => {
            this.$nprogress.done();
            let bills = response.data.data;
            if (bills.length > 0) {
              bills.sort(function (a, b) {
                return (
                  new Date(b.createdDateTime).getTime() -
                  new Date(a.createdDateTime).getTime()
                );
              });
              this.items = bills;
              this.pushedBills = this.items
                .filter((a) => a.push)
                .map((x) => x.idBill);
            } else {
              makeToastSuccess('Không có dữ liệu');
            }
            this.onBusy = false;
            this.onLoading = false;
          })
          .catch(() => {
            this.$nprogress.done();
            makeToastFaile('Lấy dữ liệu lỗi');
            this.onBusy = false;
            this.onLoading = false;
          });
      } else {
        this.items = [];
      }
    },
    pushToMBF() {
      Swal.fire({
        title: "<h1 class='text-danger'>Bạn có chắc muốn đẩy lệnh</h1>",
        text: 'Kiểm tra trước khi thực hiện thao tác này vì lệnh được đẩy đi thì không thể hoàn tác!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Trở về',
      }).then((result) => {
        if (result.value) {
          if (this.cbSelected.length > 0) {
            Swal.fire({
              title: '<h1>Đang đẩy lệnh...</h1>',
              icon: 'warning', // add html attribute if you want or remove
              allowOutsideClick: false,
              onBeforeOpen: () => {
                Swal.showLoading();
              },
            });

            const newItems = [];
            this.cbSelected.forEach((ele) => {
              newItems.push(this.items.find((x) => x.idBill === ele));
            });

            ApiService.post('mbf/transactions/confirm', { data: newItems })
              .then(() => {
                setTimeout(() => {
                  Swal.close();
                  this.Toast.fire({
                    icon: 'success',
                    title: 'Đẩy dữ thành công',
                  });
                }, 2000);
                this.fetchData();
              })
              .catch(() => {
                Swal.close();
                this.Toast.fire({
                  icon: 'error',
                  title: 'Đẩy dữ liệu thất bại',
                });
              });
          } else {
            this.Toast.fire({
              icon: 'warning',
              title: 'Vui chọn đơn để đẩy',
            });
          }
        }
      });
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY hh:mm:ss');
    },
    formatPrice(value) {
      return new Intl.NumberFormat('vi-VN').format(value);
    },
    formatStore(value) {
      return (
        value + '<br/>' + this.listStore.find((x) => x.code === value).short
      );
    },
    formatCustomer(customer, mobile) {
      return (
        '<strong>' + customer + '</strong>' + '<br/><span>' + mobile + '</span>'
      );
    },
    formatProductName(productName, barCode, flag) {
      let nameHtml = productName
        ? '<span>' + productName + '</span>'
        : "<span class='text-danger'> Chưa set tên</span>";
      if (flag) {
        nameHtml = nameHtml + '<br/><b>' + barCode + '</b>';
      }

      return nameHtml;
    },
    showDatePicker() {
      this.datePicked = this.currentDateDisplay;
      this.$refs['my-date-picker'].shown();
    },
    showPushButton() {
      let check = true;
      if (!this.selectedStore) {
        check = false;
      }
      if (!this.items) {
        check = false;
      } else {
        if (this.items.length > 0) {
          check = !(
            this.items.filter((x) => x.push == true).length ===
            this.items.length
          );
        } else {
          check = false;
        }
      }
      return check;
    },
    getStateStoreModal() {
      // if (this.datePicked != null && this.selectedStore != null) {
      //   let url =
      //     process.env.VUE_APP_BASE_URL +
      //     `store-state/search?date=${this.datePicked}&depotId=${this.selectedStore}`;
      //   axios({
      //     url: url,
      //     method: 'GET',
      //   }).then((response) => {
      //     if (response.data.status === 1) {
      //       this.selected = response.data.data.selected || [];
      //       this.description = response.data.data.description || '';
      //     }
      //   });
      // }
    },
    changeStatus() {
      let model = {
        date: this.datePicked,
        description: this.description,
        depotId: this.selectedStore,
        selected: JSON.stringify(this.selected),
      };
      if (this.datePicked && this.selectedStore) {
        let url = process.env.VUE_APP_BASE_URL + 'store-state';
        axios({
          url: url,
          method: 'PUT',
          data: model,
        })
          .then((response) => {
            if (response.data.status === 1) {
              this.Toast.fire({
                icon: 'success',
                title: 'Lưu dữ liệu thành công',
              });
            } else {
              this.Toast.fire({
                icon: 'error',
                title: 'Thất bại',
              });
            }
          })
          .catch(() =>
            this.Toast.fire({
              icon: 'error',
              title: 'Có lỗi xảy ra',
            }),
          );
      } else {
        this.Toast.fire({
          icon: 'error',
          title: 'Vui lòng chọn cửa hàng và ngày',
        });
      }
    },
    reportClick: async function () {
      if (this.selectedStore && this.datePicked !== '') {
        // set spinner to submit button
        Swal.fire({
          title: '<h1>Đang tải File...</h1>',
          icon: 'warning', // add html attribute if you want or remove
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        this.onBusy = true;

        const baseUrl = BASE_URL;

        const url = `${baseUrl}mbf/transactions/bbbg?date=${this.datePicked}&storeId=${this.selectedStore}`;

        await axios({
          url: url,
          method: 'POST',
          responseType: 'blob',
        })
          .then((response) => {
            const fileURL = window.URL.createObjectURL(
              new Blob([response.data]),
            );
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            let storeName = '';
            this.listStore.forEach((element) => {
              if (element.id === this.selectedStore) {
                storeName = element.short;
              }
            });
            const fileName =
              moment(this.datePicked).format('YYYYMMDD') +
              '_BBBG_ddv_' +
              storeName;
            fileLink.setAttribute('download', `${fileName}.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
            Swal.close();
          })
          .catch((error) => {
            console.log('error', error);
            this.Toast.fire({
              icon: 'error',
              title: 'Không có dữ liệu',
            });
          });

        this.onBusy = false;
      } else {
        this.Toast.fire({
          icon: 'error',
          title: 'Vui lòng chọn cửa hàng và ngày',
        });
      }
    },
    select() {
      this.cbSelected = [];
      if (!this.cbSelectAll) {
        for (let i in this.items) {
          if (!this.pushedBills.includes(i)) {
            this.cbSelected.push(this.items[i].idBill);
          }
        }
      }
    },
    selectOne(e) {
      if (e.target.checked) {
        this.cbSelected.push(e.target.value);
      }
    },
    stripHtmlText(text) {
      return text;
    },
    updateBill(billId, productId) {
      ApiService.put('mbf/transactions/update', {
        data: {
          billId: billId,
          productId: productId,
        },
      }).then((response) => {
        if (response.data.status) {
          if (!response.data.data) {
            this.Toast.fire({
              icon: 'info',
              title: response.data.message,
            });
          } else {
            this.Toast.fire({
              icon: 'success',
              title: response.data.message,
            });
          }
        } else {
          this.Toast.fire({
            icon: 'error',
            title: response.data.message,
          });
        }
      });
    },
    setTextStatus(status) {
      let newStatus = JSON.parse(status);
      if (newStatus.length > 0) {
        let str = '';
        newStatus.forEach((x) => {
          if (x === 1) {
            str +=
              "<b class='badge badge-danger mb-1'> Chưa set tên  </b> <br/>";
          }

          if (x === 2) {
            str +=
              "<b class='badge badge-danger mb-1'> Barcode không đúng  </b> <br/>";
          }

          if (x === 3) {
            str +=
              "<b class='badge badge-danger mb-1'> Trùng Serial / Imei đã đẩy  </b> <br/>";
          }

          if (x === 4) {
            str +=
              "<b class='badge badge-danger mb-1'> Chiết khẩu tổng  </b> <br/>";
          }
          if (x === 5) {
            str +=
              "<b class='badge badge-danger mb-1'>Chưa set thuế suất  </b> <br/>";
          }
        });
        return str;
      } else if (newStatus.length === 0) {
        return "<span class='badge badge-success'> Hợp lệ  </span>";
      }
    },
    checkBillValid(idBill) {
      let groupBills = this.items.filter((x) => x.idBill === idBill);
      let check = false;
      groupBills.forEach((element) => {
        if (JSON.parse(element.status).length > 0) {
          check = true;
        }
      });
      return check;
    },
    checkBillApprove(idBill) {
      let groupBills = this.items.filter((x) => x.idBill === idBill);
      let check = false;
      groupBills.forEach((element) => {
        if (!element.approve) {
          check = true;
        }
      });
      return check;
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then((reponse) => {
        if (reponse.data.status) {
          const data = reponse.data;
          console.log('data', data);
        } else {
          makeToastFaile('Lỗi');
        }

        // if (data.status === 1) {
        //   // let index = this.optionsStore.findIndex(
        //   //   item => item.name === option.name
        //   // );
        //   // this.optionsStore[index].checked = true;
        //   const stores = data.data.stores;
        //   if (stores.length === 1) {
        //     this.apiParams.storeId = [stores[0].id.toString()];
        //     let option = {
        //       code: stores[0].id,
        //       name: stores[0].shortName,
        //       checked: true,
        //       $isDisabled: true,
        //     };
        //     this.searchStock.push(option);
        //     this.optionsStore = [option];
        //   } else {
        //     stores.forEach((element) => {
        //       if (data.data.viewAll) {
        //         let option = {
        //           code: element.id,
        //           name: element.shortName,
        //           checked: false,
        //           $isDisabled: false,
        //         };
        //         this.optionsStore.push(option);
        //       } else {
        //         let option = {
        //           code: element.id,
        //           name: element.shortName,
        //           checked: true,
        //           $isDisabled: true,
        //         };
        //         this.searchStock.push(option);
        //         this.optionsStore.push(option);
        //         this.apiParams.storeId.push(option.code);
        //       }
        //     });
        //   }
        // }
        // this.fetchMainData();
      });
    },
  },
};
</script>
<style lang="scss">
.mbf-transaction {
  .icon-size {
    font-size: 1.4rem;
  }

  .select-style {
    border-radius: 0.28rem;
    box-shadow: none;
    border: 1px solid #ced4da;
  }
}

.table-sale {
  .date-col {
    width: 10%;
  }
  .quantity-col {
    width: 1%;
  }
  .status-col {
    width: 8%;
  }
  .check-col {
    width: 8%;
  }
  .select {
    text-align-last: center;
  }

  .store-col {
    width: 10%;
  }
  .customer-col {
    width: 14%;
  }
  .checkbox-col {
    width: 5%;
  }
  th {
    text-align: center;
    background-color: #f5f5f5;
  }
  .product-col {
    width: 20%;
  }
}
.table-font-size {
  font-size: 12px;
}
.disabled {
  pointer-events: none;
}

[type='checkbox'][readonly='readonly']::before {
  background: rgba(255, 255, 255, 0.5);
  content: '';
  display: block;
  height: 100%;
  width: 100%;
}
</style>
